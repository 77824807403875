import React, {FC} from "react";
import {Divider, List, ListItem, ListItemText, Typography} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";

interface RouteDocumentationProps extends React.PropsWithChildren {
  routeTitle: string;
  routeExplanation: string;
  routeType: string;
  route: string;
}

const BASE_URL = process.env.REACT_APP_URL!

export const RouteDocumentation: FC<RouteDocumentationProps> = ({routeTitle, routeExplanation, routeType, route, children}) => {
  return (
    <>
      <Accordion sx={{
        backgroundColor: "transparent",
        backgroundImage: "none",
        boxShadow: 0
      }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={"panel-" + route + "-content"}
          id={"panel-" + route + "-header"}
        >
          <Typography variant="h6">{routeTitle}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{marginLeft: 2, marginRight: 2, borderTop: 1, borderColor: 'rgba(255, 255, 255, 0.12)'}}>
          <List>
            <ListItem>
              <ListItemText primary={routeExplanation}/>
              <List disablePadding>
                <ListItem sx={{paddingTop: 0, paddingBottom: 0}}>
                  <ListItemText primary={"Route - " + routeType} secondary={"'" + BASE_URL + route + "'"}/>
                </ListItem>
              </List>
            </ListItem>
            {children}
          </List>
        </AccordionDetails>
      </Accordion>
      <Divider orientation="horizontal" variant="middle" flexItem/>
    </>
  )
}