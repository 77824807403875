import {FC, useContext, useEffect, useRef, useState} from "react";
import {UserContext} from "../../../../context/user-context";
import axios from "axios";
import {getErrorMessage} from "../../../../utils/notificationCenter";
import {Box, Grid, LinearProgress, List, Paper, Typography} from "@mui/material";
import {Bill, BillInfo} from "./components/Bill";

const CENTRAL_API_URL = process.env.REACT_APP_CENTRAL_API_URL!
const BILLS_ALL_ROUTE = process.env.REACT_APP_BILLS_ALL_ROUTE!
const PURCHASE_MAX_AMOUNT_ROUTE = process.env.REACT_APP_PURCHASE_MAX_AMOUNT_ROUTE!


export const BillsBoard: FC = () => {
  const requestSent = useRef<boolean>(false);
  const {user} = useContext(UserContext);
  const [bills, setBills] = useState<BillInfo[]>([]);
  const [maxAmount, setMaxAmount] = useState<number>(600);

  useEffect(() => {
    if (!requestSent.current && user) {
      axios.post(CENTRAL_API_URL + BILLS_ALL_ROUTE, {
        "jwt": user.jwt
      })
        .then(response => {
          setBills(response.data.bills);
        })
        .catch(error => console.log("Error on fetching bills from database : ", getErrorMessage(error)))
      if (user.plan !== "BUSINESS") {
        axios.post(CENTRAL_API_URL + PURCHASE_MAX_AMOUNT_ROUTE, {
          "jwt": user.jwt
        })
          .then(response => {
            setMaxAmount(response.data);
          })
          .catch(error => console.log("Error on fetching max purchase amount from database : ", getErrorMessage(error)))
      }
      requestSent.current = true;
    }
  }, [user]);

  return (
    <Box sx={{margin: 2, flexGrow: 1, border: 1, borderRadius: "4px", borderColor:'secondary.main', padding: "16px"}}>
      <Grid container spacing={2} sx={{textAlign: 'center'}} >
        <Grid item xs={12}>
          <Paper variant="outlined">
            <Typography variant="h5">Bills history</Typography>
            {user?.plan === "BUSINESS" ? <></> :
              <>
                <Box sx={{display: "flex", justifyContent: 'space-around', alignItems: 'center'}}>
                  Purchase remaining :
                  <LinearProgress sx={{width: "80%", height: 10, borderRadius: "4px"}} color={maxAmount < 60 ? maxAmount === 0 ? "error" : "warning" : "secondary"}  variant="determinate" value={100 - (maxAmount / 6)}/>
                  {maxAmount} / 600 tokens
                </Box>
                <Typography variant="caption">Your plan allows you up to 600 purchased tokens on a 30 days period. Need more and can't wait ? Upgrade to Business plan.</Typography>
              </>
            }

          </Paper>
        </Grid>
        <Grid container justifyContent="center" sx={{maxHeight: 600, overflow: 'auto', padding: "16px"}}>
          <List sx={{width: "100%"}}>
            {bills.map((request: BillInfo) => <Bill
              key={request._id}
              data={request}
            />)}
          </List>
        </Grid>
      </Grid>
    </Box>
  )
}