import React, {ChangeEvent, FC, useContext, useEffect, useRef, useState} from "react";
import {Paper, TextField, Typography, Grid, Box, Checkbox} from "@mui/material";
import {UserContext} from "../../../../../context/user-context";
import axios from 'axios';
import {getErrorMessage, notifyUser} from "../../../../../utils/notificationCenter";
import {useSnackbar} from "notistack";
import {PersonalInfos} from "../../../../../user/infosProvider";
import {CountrySelect} from "../../info_board/components/CountrySelect";

const CENTRAL_API_URL = process.env.REACT_APP_CENTRAL_API_URL!
const BILLING_INFO_ROUTE = process.env.REACT_APP_BILLING_INFO_ROUTE!

interface BillingInfoFormProps {
  infos: BillingInfo
  setInfos: (newElem: BillingInfo) => void
  keepInfo: boolean
  setKeepInfo: (newState: boolean) => void
}

export type BillingInfo = PersonalInfos & {
  "city": string,
  "address": string,
  "companyName": string,
  "vatNumber": string,
}

export const BillingInfoForm: FC<BillingInfoFormProps> = ({infos, setInfos, keepInfo, setKeepInfo}) => {
  const {user} = useContext(UserContext);
  const requestSent = useRef<boolean>(false);
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [isCompany, setIsCompany] = useState(false);

  useEffect(() => {
    if (!requestSent.current && user) {
      axios.post(CENTRAL_API_URL + BILLING_INFO_ROUTE, {
        "jwt": user.jwt
      })
        .then(response => {
          if (response.data.firstName !== "") {
            setInfos({
              firstName: response.data.firstName,
              lastName: response.data.lastName,
              country: response.data.country,
              city: response.data.city,
              address: response.data.address,
              companyName: response.data.companyName,
              vatNumber: response.data.vatNumber,
            });
          }
        })
        .catch(error => {
          notifyUser("Error on fetching billing infos from database : " + getErrorMessage(error), true, enqueueSnackbar, closeSnackbar);
        });
      requestSent.current = true;
    }
  }, [closeSnackbar, enqueueSnackbar, setInfos, user])

  useEffect(() => {
    if (!isCompany && infos.companyName !== "") {
      setIsCompany(true);
    }
  }, [isCompany, infos]);

  const handleKeepInfoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeepInfo(event.target.checked);
  };

  const handleCompanyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsCompany(event.target.checked);
    if (!event.target.checked) {
      setInfos({
        ...infos,
        companyName: '',
        vatNumber: '',
      });
    }
  };

  const handleFirstNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInfos({
      ...infos,
      firstName: event.target.value,
    });
  };

  const handleLastNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInfos({
      ...infos,
      lastName: event.target.value,
    });
  };

  const handleCityChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInfos({
      ...infos,
      city: event.target.value,
    });
  };

  const handleAddressChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInfos({
      ...infos,
      address: event.target.value,
    });
  };

  const handleCompanyNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInfos({
      ...infos,
      companyName: event.target.value,
    });
  };

  const handleVATNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInfos({
      ...infos,
      vatNumber: event.target.value,
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4} sx={{textAlign: 'left'}}>
        <Typography>First Name</Typography>
        <Paper variant={"outlined"}  sx={{marginTop: 0.5, marginBottom: 1}}>
          <TextField value={infos?.firstName} error={infos?.firstName === ""} helperText={infos?.firstName === "" ? "Please provide your first name." : ""} onChange={handleFirstNameChange} fullWidth size="small"/>
        </Paper>
      </Grid>
      <Grid item xs={12} md={4} sx={{textAlign: 'left'}}>
        <Typography>Last Name</Typography>
        <Paper variant={"outlined"}  sx={{marginTop: 0.5, marginBottom: 1}}>
          <TextField value={infos?.lastName} error={infos?.lastName === ""} helperText={infos?.lastName === "" ? "Please provide your last name." : ""} onChange={handleLastNameChange} fullWidth size="small"/>
        </Paper>
      </Grid>
      <Grid item xs={12} md={4} sx={{textAlign: 'left'}}>
        <Typography>Country</Typography>
        <Paper variant={"outlined"}  sx={{marginTop: 0.5, marginBottom: 1}}>
          <CountrySelect visibleInfos={infos} setVisibleInfos={setInfos}/>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6} sx={{textAlign: 'left'}}>
        <Typography>City</Typography>
        <Paper variant={"outlined"}  sx={{marginTop: 0.5, marginBottom: 1}}>
          <TextField value={infos?.city} error={infos?.city === ""} helperText={infos?.city === "" ? "Please provide your city." : ""} onChange={handleCityChange} fullWidth size="small"/>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6} sx={{textAlign: 'left'}}>
        <Typography>Address</Typography>
        <Paper variant={"outlined"}  sx={{marginTop: 0.5, marginBottom: 1}}>
          <TextField value={infos?.address} error={infos?.address === ""} helperText={infos?.address === "" ? "Please provide your address." : ""} onChange={handleAddressChange} fullWidth size="small"/>
        </Paper>
      </Grid>
      <Grid item xs={12} sx={{textAlign: 'center'}}>
        <Box component={"div"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <Checkbox color={"secondary"} checked={isCompany} onChange={handleCompanyChange} inputProps={{ 'aria-label': 'controlled' }}/>
          <Typography sx={{textAlign: "center"}}>I represent a company.</Typography>
        </Box>
      </Grid>
      {isCompany ?
        <>
          <Grid item xs={12} md={6} sx={{textAlign: 'left'}}>
            <Typography>Company Name</Typography>
            <Paper variant={"outlined"}  sx={{marginTop: 0.5, marginBottom: 1}}>
              <TextField value={infos?.companyName} error={isCompany && infos?.companyName === ""} helperText={isCompany && infos?.companyName === "" ? "Please provide your company name." : ""} onChange={handleCompanyNameChange} fullWidth size="small"/>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} sx={{textAlign: 'left'}}>
            <Typography>VAT Number</Typography>
            <Paper variant={"outlined"}  sx={{marginTop: 0.5, marginBottom: 1}}>
              <TextField value={infos?.vatNumber} onChange={handleVATNumberChange} fullWidth size="small"/>
            </Paper>
          </Grid>
        </> : <></>
      }
      <Grid item xs={12} sx={{textAlign: 'center'}}>
        <Box component={"div"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <Checkbox color={"secondary"} checked={keepInfo} onChange={handleKeepInfoChange} inputProps={{ 'aria-label': 'controlled' }}/>
          <Typography sx={{textAlign: "center"}}>Save billing infos for further purchase.</Typography>
        </Box>
      </Grid>
    </Grid>
  )
};