import React, {FC, useContext, useRef, useState} from "react";
import {getCurrentDate} from "../../../../../../../../../utils/dateUtils";
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';
import { MediaRecorder, register } from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';
import {Button} from "@mui/material";
import {PlaygroundContext} from "../../../../../../../../../context/playground-context";


interface AudioRecorderProps {
    setFile: (newFile : File | undefined) => void;
}

export const AudioRecorder: FC<AudioRecorderProps> = ({setFile}) => {
    const [isRecording, setRecording] = useState<boolean>(false);
    const [isRegistered, setRegistered] = useState<boolean>(false);
    const [audioChunks, setAudioChunks] = useState<Blob[]>([]);
    const [stream, setStream] = useState<MediaStream>()
    const mediaRecorder = useRef<MediaRecorder>();
    const {playgroundEnabled} = useContext(PlaygroundContext);

    const buttonHandler = async (_: React.MouseEvent<HTMLButtonElement>) => {
        if ("MediaRecorder" in window) {
            if (!isRecording) {
                if (!isRegistered) {
                    await register(await connect());
                    setRegistered(true);
                }
                await navigator.mediaDevices.getUserMedia({
                    audio: true,
                    video: false,
                }).then((stream) => {
                    setRecording(true);
                    setStream(stream);
                    // @ts-ignore
                    mediaRecorder.current = new MediaRecorder(stream, {mimeType: "audio/wav"});
                    if (mediaRecorder.current) {
                        mediaRecorder.current.start();
                        let localAudioChunks: Blob[] = [];
                        mediaRecorder.current.ondataavailable = (event) => {
                            if (typeof event.data === "undefined" || event.data.size === 0) {
                                return;
                            }
                            localAudioChunks.push(event.data);
                        };
                        setAudioChunks(localAudioChunks);
                    }
                }).catch((r) => {
                    console.log("A problem occurred while accessing microphone." + r);

                })
            } else {
                setRecording(false);
                if (mediaRecorder.current) {
                    mediaRecorder.current.stop();
                    mediaRecorder.current.onstop = () => {
                        const audioBlob = new Blob(audioChunks, {type: "audio/x-wav"});
                        setFile(new File([audioBlob], getCurrentDate() + ".wav"));
                        setAudioChunks([]);
                        if (stream !== undefined) {
                            stream.getTracks().forEach(function(track) {
                                track.stop();
                            });
                            setStream(undefined);
                        }

                    };
                }
            }
        } else {
            alert("Your browser cannot use this feature.");
        }
    };

    return (
        <>
            <Button disabled={playgroundEnabled} onClick={buttonHandler} sx={{padding: 0, paddingLeft: 1, paddingRight: 1, margin: 0, marginLeft: 1, marginRight: 1}} variant="contained" startIcon={isRecording ? <StopIcon/> : <MicIcon/>}>
                {isRecording ? "Stop" : "Record"}
            </Button>
        </>
    );
};