import React, {FC, useEffect, useRef, useState} from "react";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { throttle } from "throttle-typescript";
import {TimeStamp} from "./components/TimeStamp";
import {PlayPauseButton} from "./components/PlayPauseButton";
import {Divider} from "@mui/material";
import {AudioDownloadButton} from "./components/AudioDownloadButton";

interface AudioPlayerProps {
    file: File | undefined;
}

export const AudioPlayer : FC<AudioPlayerProps> = ({file}) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [playing, setPlaying] = useState<boolean>(false);
    const [position, setPosition] = useState<number>(0);
    const [currentTime, setCurrentTime] = useState<number>(0);
    const [endTime, setEndTime] = useState<number>(0);
    const [audioElement, setAudioElement] = useState<HTMLAudioElement | null>(null);
    const lock = useRef<boolean>(false);

    // This lock prevent a hook loop as we want to stop the previous audioElement while creating a new one in the same useEffect, this will call the effect again
    // Didn't find a better solution
    useEffect(() => {
        if (!lock.current) {
            audioElement?.pause();
            setAudioElement(null);
            if (file !== undefined) {
                const audio = new Audio(URL.createObjectURL(file));
                setLoading(true);

                audio.addEventListener("canplaythrough", () => {
                    setLoading(false);
                    setCurrentTime(0);
                    setPosition(0);
                    setEndTime(audio.duration);
                });
                audio.addEventListener("playing", () => setPlaying(true));
                audio.addEventListener(
                  "timeupdate",
                  throttle(() => {
                      setCurrentTime(audio.currentTime);
                      setPosition((audio.currentTime / audio.duration) * 100);
                  }, 100)
                );
                audio.addEventListener("pause", () => setPlaying(false));
                audio.addEventListener("ended", () => setPlaying(false));
                audio.addEventListener("error", () => setPlaying(false));

                setPlaying(false);
                setAudioElement(audio);
                lock.current = true;
            }
        } else {
            lock.current = false;
        }
    }, [audioElement, file]);

    let innerComponent;
    if (audioElement) {
        innerComponent =
            <>
                <AudioDownloadButton disabled={loading} file={file}/>
                <PlayPauseButton disabled={loading} audioElement={audioElement} playing={playing}/>
                {loading ? <LinearProgress/> : null}
                <Stack component={Box} direction="row" flexGrow={loading ? 0 : 1} height="100%" width="100%" alignItems="center" spacing={1} >
                    <TimeStamp time={currentTime} loading={loading}/>
                    <Box flexGrow={1} height="100%" width="100%" alignItems="center">
                        {!loading && (
                            <Box mx={1} display="flex" alignItems="center" height="100%">
                                <Slider
                                    onChange={(_, v) => {
                                        if (audioElement && typeof v === "number") {
                                            const currentPosition = (audioElement.duration / 100) * v;

                                            audioElement.fastSeek(currentPosition);
                                        }}
                                    } size={"small"} value={position}
                                />
                            </Box>
                        )}
                    </Box>
                    <TimeStamp time={endTime} loading={loading}/>
                </Stack>
            </>
    } else {
        innerComponent = <></>
    }

    return (
        <>
            <Paper elevation={0} sx={{borderRadius: 2, flexGrow: 1, display: 'inline-flex', alignItems: "center", justifyContent: "space-evenly", marginRight: 1}}>
                {innerComponent}
            </Paper>
            {audioElement ? <Divider orientation={"vertical"} flexItem /> : <></>}
        </>
    );
}