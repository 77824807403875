import {ChangeEvent, FC, useContext, useState} from "react";
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    Input,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography
} from "@mui/material";
import {SelectChangeEvent} from "@mui/material/Select";
import {getErrorMessage, notifyUser} from "../../../../utils/notificationCenter";
import {useSnackbar} from "notistack";
import {UserContext} from "../../../../context/user-context";
import axios from 'axios';

type CreateNotifForm = {
    "text": string,
    "expireAt": Date,
    "priority": string,
}

const CENTRAL_API_URL = process.env.REACT_APP_CENTRAL_API_URL!
const NOTIFICATION_CREATE_ROUTE = process.env.REACT_APP_NOTIFICATION_CREATE_ROUTE!

export const CreateNotifBoard: FC = () => {
    const {user, validUser} = useContext(UserContext);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const [requestData, setRequestData] = useState<CreateNotifForm>({
        text: "",
        expireAt: new Date(),
        priority: "info.main",
    });

    const dataHandler = (event: ChangeEvent | SelectChangeEvent) => {
        const {name, value} = event.target as HTMLInputElement;
        setRequestData({
            ...requestData,
            [name]: value
        })
    }

    const requestHandler = () => {
        validUser(user);
        if (user?.perm === "ADMIN") {
            axios.post(CENTRAL_API_URL + NOTIFICATION_CREATE_ROUTE, {
                "jwt": user.jwt,
                ...requestData
            })
                .then(() => notifyUser("Notification effectively created.", false, enqueueSnackbar, closeSnackbar))
                .catch(error => notifyUser("An error occurred while creating account : " + getErrorMessage(error), true, enqueueSnackbar, closeSnackbar));
        } else {
            notifyUser("You must be a connected administrator to do this.", true, enqueueSnackbar, closeSnackbar)
        }
    }

    return (
        <Box sx={{
            margin: 2,
            flexGrow: 1,
            border: 1,
            borderRadius: "4px",
            borderColor: 'secondary.main',
            padding: "16px"
        }}>
            <form>
                <Grid container spacing={2} sx={{textAlign: 'center'}}>
                    <Grid item xs={12}>
                        <Paper variant="outlined">
                            <Typography variant="h5">Create notification form</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper variant="outlined"
                               sx={{display: "flex", justifyContent: "space-around", paddingRight: 2}}>
                            <Typography sx={{flexGrow: 1}} alignSelf="center">Text :</Typography>
                            <FormControl sx={{flexGrow: 2}}>
                                <Input onChange={dataHandler} value={requestData.text} type="text" name="text"
                                       id="notif-txt" aria-describedby="notif-text-helper"/>
                                <FormHelperText id="notif-text-helper">The text for the notification</FormHelperText>
                            </FormControl>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper variant="outlined"
                               sx={{display: "flex", justifyContent: "space-around", paddingRight: 2}}>
                            <Typography sx={{flexGrow: 1}} alignSelf="center">Priority :</Typography>
                            <FormControl sx={{flexGrow: 2}}>
                                <Select onChange={dataHandler} value={requestData.priority} name="priority"
                                        variant="standard" id="notif-priority" aria-describedby="notif-priority-helper">
                                    <MenuItem value={"info.main"}>Info</MenuItem>
                                    <MenuItem value={"success.main"}>Success</MenuItem>
                                    <MenuItem value={"warning.main"}>Warning</MenuItem>
                                    <MenuItem value={"error.main"}>Error</MenuItem>
                                </Select>
                                <FormHelperText id="notif-priority-helper">The priority of the
                                    notification</FormHelperText>
                            </FormControl>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper variant="outlined"
                               sx={{display: "flex", justifyContent: "space-around", paddingRight: 2}}>
                            <Typography sx={{flexGrow: 1}} alignSelf="center">Expire date :</Typography>
                            <FormControl sx={{flexGrow: 2}}>
                                <TextField variant={"standard"} onChange={dataHandler} value={requestData.expireAt} type="date"
                                           name="expireAt" aria-describedby="notif-date-helper"/>
                                <FormHelperText id="notif-date-helper">The expire date for the
                                    notification</FormHelperText>
                            </FormControl>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sx={{margin: 2}}>
                        <Button onClick={requestHandler} sx={{fontSize: "1em"}} variant="contained">
                            <Typography sx={{textAlign: "center"}}>Create notification</Typography>
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Box>
    )
}