import {FC} from "react";
import {Box, Typography} from "@mui/material";

export const AdministrationHomeBoard: FC = () => {
    return (
        <Box justifyContent="center" sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
            height: 500,
            textAlign:"center"
        }}>
            <Typography align="center" variant={"h3"}>Welcome to the admin dashboard</Typography>
        </Box>

    )
}