import React, {useEffect, useState} from "react";
import {CHARTS_BORDER_COLORS, Stat} from "../StatisticsBoard";
import {Bar} from "react-chartjs-2";
import {
    BarElement,
    CategoryScale,
    Chart,
    Legend,
    LinearScale,
    Title,
    Tooltip
} from "chart.js";
import {Paper, Stack} from "@mui/material";

interface StatsAnimationLengthChartProps {
    stats: Stat[],
}

interface Dataset {
    label: string,
    data: number[],
    backgroundColor: string,
}

interface Datasets {
    labels: string[]
    datasets : Dataset[]
}

Chart.register( CategoryScale, LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend);


function getBinIndex(cost: number) {
    if (cost <= 5) {
        return 0;
    } else if (cost <= 15) {
        return 1;
    } else if (cost <= 30) {
        return 2;
    } else if (cost <= 60) {
        return 3;
    } else if (cost <= 120) {
        return 4;
    } else if (cost <= 300) {
        return 5;
    } else {
        return 6;
    }
}

function arrayToDataset(stats: Stat[]) {
    const bins = ["0-5", "6-15", "16-30", "31-60", "60-120", "120-300", "300+"]
    const labels = Array.from(new Set(stats.map(item => item.user)));

    let datasets: Dataset[] = labels.map(user_name => ({
        label: user_name,
        data: bins.map(() => 0),
        backgroundColor: CHARTS_BORDER_COLORS[labels.indexOf(user_name) % CHARTS_BORDER_COLORS.length],
    }));
    datasets.push({
        label: "Total",
        data: bins.map(() => 0),
        backgroundColor: "black",
    });

    stats.forEach(item => {
        let binIdx = getBinIndex(item.cost);
        datasets[labels.indexOf(item.user)].data[binIdx] += 1
        datasets[datasets.length - 1].data[binIdx] += 1
    });

    return {
        datasets: datasets,
        labels: bins
    };
}

export const StatsAnimationLengthChart: React.FC<StatsAnimationLengthChartProps> = ({stats}) => {
    const plugin = {
        id: 'customCanvasBackgroundColor',
        beforeDraw: (chart: any, args: any, options: any) => {
            const {ctx} = chart;
            ctx.save();
            ctx.globalCompositeOperation = 'destination-over';
            ctx.fillStyle = "white";
            ctx.fillRect(0, 0, chart.width, chart.height);
            ctx.restore();
        }
    };

    const options = {
        responsive: true,
        maintainAspectRatio : false,
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Amount produced'
                },
                ticks: {
                    stepSize: 1,
                },
            },
            x: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Tokens cost'
                }
            },
        },
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: "Animation length created by users"
            }
        },
    };

    const [datasets, setDatasets] = useState<Datasets>({
        labels: [],
        datasets: []
    });

    useEffect(() => {setDatasets(arrayToDataset(stats))}, [stats]);

    return (
        <Paper component={Stack} display="flex" justifyContent="center" sx={{backgroundColor: "white", paddingTop: "16px", paddingBottom: "16px"}}>
        <div style={{position: "relative", height: "70vh", width: "80vw"}}>
            <Bar options={options} data={datasets} plugins={[plugin]}/>
        </div>
    </Paper>
)
}