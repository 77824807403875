import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import React, {FC, useContext, useState} from "react";
import logo from './res/DYNALIPS_LOGO_COLOR.png';
import {Divider, Link, useTheme} from "@mui/material";
import {UserContext} from "./context/user-context";
import {Help} from "@mui/icons-material";
import {removeCookie} from "typescript-cookie";
import {EuroIcon} from "./utils/components/EuroIcon";
import Tooltip from "@mui/material/Tooltip";


const ID_PROVIDER_FRONT_URL = process.env.REACT_APP_ID_PROVIDER_FRONT_URL
const REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL

export const HeadBar: FC = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [connect, isConnect] = useState<boolean>(false);
    const {user, setUser} = useContext(UserContext);
    const theme = useTheme();

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleConnect = () => {
        setAnchorEl(null);
        isConnect(true)
    };

    const handleDisconnect = () => {
        setAnchorEl(null);
        setUser(undefined);
        sessionStorage.clear();
        removeCookie("jwt");
        isConnect(false);
        window.location.href = ID_PROVIDER_FRONT_URL + "?redirect=" + REDIRECT_URL
    };

    if (connect) {
        sessionStorage.clear();
        window.location.href = ID_PROVIDER_FRONT_URL + "?redirect=" + REDIRECT_URL
        return <> </>
    }

    let accountMenu, accountTokens
    if (user) {
        accountMenu = (
            <div>
                <Link href={"/account"} sx={{color: 'inherit', textDecoration: 'inherit'}}>
                    <MenuItem>{user.email}</MenuItem>
                </Link>
                <Link href={"/account"} sx={{color: 'inherit', textDecoration: 'inherit'}}>
                    <MenuItem>Your account</MenuItem>
                </Link>
                {user.perm === "ADMIN" && (
                    <Divider/>
                )}
                {user.perm === "ADMIN" && (
                    <Link href={"/admin"} sx={{color: 'inherit', textDecoration: 'inherit'}}>
                        <MenuItem>Administration</MenuItem>
                    </Link>
                )}
                <Divider/>
                <Link href={"/"} sx={{color: 'inherit', textDecoration: 'inherit'}}>
                    <MenuItem>Animate</MenuItem>
                </Link>
                <Link href={"/help"} sx={{color: 'inherit', textDecoration: 'inherit'}}>
                    <MenuItem>Help</MenuItem>
                </Link>
                <Divider/>
                <MenuItem onClick={handleDisconnect}>Disconnect</MenuItem>
            </div>
        )
        accountTokens = (
          <>
            <Link href={"/account/payment"}  sx={{color: 'inherit', textDecoration: 'inherit'}}>
              <Tooltip title={"Purchase tokens"}>
                <IconButton size="large" aria-label="payment" aria-controls="menu-appbar" aria-haspopup="true" color="inherit">
                  <EuroIcon />
                </IconButton>
              </Tooltip>
            </Link>
            <Typography>
              Tokens : {user.tokens}
            </Typography>
            </>
        )
    } else {
        accountMenu = (
            <div>
                <Link href={"/help"} sx={{color: 'inherit', textDecoration: 'inherit'}}>
                    <MenuItem>Help</MenuItem>
                </Link>
                <MenuItem onClick={handleConnect}>Login</MenuItem>
            </div>
        )
        accountTokens = <></>
    }

    return (
        <Box sx={{ flexGrow: 1}}>
            <AppBar position="fixed" color="primary" enableColorOnDark sx={{
                zIndex: theme.zIndex.drawer + 1,
            }}>
                <Toolbar sx={{height:60}}>
                    <Link href={"https://dynalips.com/"}>
                        <Box component="img" sx={{height: 54}} alt="Dynalips logo" src={logo}/>
                    </Link>
                    <Box  sx={{ flexGrow: 1}}/>
                    <div>
                        <Tooltip title={"Your account"}>
                          <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleMenu} color="inherit">
                              <AccountCircle/>
                          </IconButton>
                        </Tooltip>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            {accountMenu}
                        </Menu>
                    </div>
                    {accountTokens}
                    <Link href={"/help"}  sx={{color: 'inherit', textDecoration: 'inherit'}}>
                        <Tooltip title={"Documentation"}>
                          <IconButton size="large" aria-label="Tutorial" aria-controls="menu-appbar" aria-haspopup="true" color="inherit">
                              <Help/>
                          </IconButton>
                        </Tooltip>
                    </Link>
                    <Typography>v1.0.0</Typography>
                </Toolbar>
            </AppBar>
        </Box>
    );
}