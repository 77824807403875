import React, {FC, useContext, useEffect, useRef, useState} from "react";
import {SendButton} from "./components/SendButton";
import {Box, Grid, Typography} from "@mui/material";
import {ResultProps} from "../results_panel/components/Result";
import {UserContext} from "../../../../context/user-context";
import {DataInputPanel} from "./components/data_input_panel/DataInputPanel";
import {SendContext} from "../../../../context/send-context";
import {PlaygroundContext} from "../../../../context/playground-context";


interface ControlPanelProps {
    setResults: (newElem: ResultProps[] | ((prev: ResultProps[]) => ResultProps[])) => void
}

export const ControlPanel: FC<ControlPanelProps> = ({setResults}) => {
    const [notifText, setNotifText] = useState<string>("");
    const [length, setLength] = useState<number | undefined>(undefined);
    const {user} = useContext(UserContext);
    const requestData = useRef(new FormData())
    const {textProvided, textVerified} = useContext(SendContext);
    const {playgroundEnabled} = useContext(PlaygroundContext);


    useEffect(() => {
        requestData.current.set("asset", "metahuman")
        requestData.current.set("engine", "unreal5")
        requestData.current.set("language", "fr-fr")
    }, [])

    useEffect(() => {
        setNotifText("");
        if (length !== undefined) {
            if (!textProvided) {
                setNotifText("Provide the audio transcription.");
            } else {
                if (playgroundEnabled) {
                        setNotifText("Playground files are FREE to generate !");
                } else {
                    if (length > 60 && user?.perm !== "ADMIN") {
                        setNotifText("The file exceed the 1 minute limitation (only for test phase) and cannot be animated.");
                    } else {
                        setNotifText("Animate this file will cost you " + Math.ceil(length) + " tokens.");
                    }
                }

            }
        }
        if (textProvided && !textVerified) {
            setNotifText("Verify your text at least once before animate.");
        }
    }, [playgroundEnabled, textProvided, textVerified, length, user]);

    useEffect(() => {
        if (!user) {
            setResults([])
        }
    }, [setResults, user])

    return (
        <Grid item xs={12} md={8} sx={{border:1, borderRadius: "4px", borderColor:'secondary.main', padding: "16px", minHeight: "75vh"}}>
            <Typography align='center' variant='h5' sx={{marginBottom: 2}}>Control board</Typography>
            <DataInputPanel requestData={requestData.current} setLength={setLength}/>
            {notifText ? <Typography sx={{border:1, borderRadius: "4px", borderColor:'secondary.main', padding: 1, marginBottom: 1}} align='center'>{notifText}</Typography> : <></>}
            <Box sx={{width: "100%", flexDirection: 'row', display: 'inline-flex', alignItems: "center", justifyContent: "space-evenly"}}>
                <SendButton requestData={requestData.current} setResults={setResults}/>
            </Box>
        </Grid>
    )
}
