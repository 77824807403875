import {FC} from "react";
import {SvgIcon} from "@mui/material";

export const EuroIcon: FC = () => {
  return (
    <SvgIcon
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      preserveAspectRatio="xMidYMid meet">
      <path d="m 11.997962,2.000469 c -5.5195424,0 -9.9991716,4.4788063 -9.9991716,9.997335 0,5.51853 4.4796292,9.997336 9.9991716,9.997336 5.519544,0 9.999172,-4.478806 9.999172,-9.997336 0,-5.5185287 -4.479628,-9.997335 -9.999172,-9.997335 z m -0.041,4.022368 c 1.565311,0 3.001502,0.5909924 4.083643,1.5640331 L 14.835625,8.791629 C 14.059723,8.1249067 13.052636,7.7235537 11.956966,7.7235537 c -1.701438,0 -3.1845753,0.9668223 -3.9196045,2.3821753 h 4.6011895 l -0.681585,1.360967 H 7.5881876 c -0.032098,0.2246 -0.054696,0.44826 -0.054696,0.679509 0,0.231358 0.020699,0.457018 0.054696,0.681458 H 12.63855 l -0.681585,1.360968 H 8.0373605 c 0.7350292,1.415322 2.2113565,2.380225 3.9196045,2.380225 1.10247,0 2.103697,-0.401243 2.872802,-1.068076 l 1.210841,1.20476 c -1.082141,0.973 -2.511533,1.565982 -4.083643,1.565982 -2.6678681,0 -4.9271811,-1.708164 -5.7710112,-4.082891 H 3.1100384 L 3.791622,12.827663 h 2.0818577 c -0.030197,-0.22452 -0.040997,-0.45021 -0.040997,-0.681458 0,-0.231399 0.014599,-0.455059 0.040997,-0.679509 H 3.1100384 L 3.791622,10.105729 H 6.1859538 C 7.0297839,7.7310116 9.2890969,6.022837 11.956966,6.022837 Z"/>
    </SvgIcon>
  );
}