import {createContext} from "react";


export const SendContext = createContext<{
    spammingProtection: boolean;
    audioProvided: boolean;
    textProvided: boolean;
    textVerified: boolean;
    setSpammingProtection: (state: boolean) => void;
    setAudioProvided: (state: boolean) => void;
    setTextProvided: (state: boolean) => void;
    setTextVerified: (state: boolean) => void;
}>({
    spammingProtection: false,
    audioProvided: false,
    textProvided: false,
    textVerified: false,
    setSpammingProtection: (_: boolean) => {},
    setAudioProvided: (_: boolean) => {},
    setTextProvided: (_: boolean) => {},
    setTextVerified: (_: boolean) => {},
})

