import {BillingInfo} from "../../payment_board/components/BillingInfoForm";
import {FC, useState} from "react";
import {Divider, Grid, ListItem, ListItemButton, ListItemText, Paper, Typography} from "@mui/material";
import {KeyboardArrowDown} from "@mui/icons-material";

const iso3311a2 = require('iso-3166-1-alpha-2');

export interface BillProps {
  data: BillInfo,
}

export type BillInfo = BillingInfo & {
  date: string;
  transId: string;
  amount: number;
  price: number;
  _id: string;
}

export const Bill: FC<BillProps> = ({data}) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <ListItem sx={{backgroundColor: "#340b64CC", borderRadius: "8px", marginBottom: "16px"}}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ListItemButton alignItems="flex-start" onClick={() => setOpen(!open)}>
            <KeyboardArrowDown
              sx={{
                transform: open ? 'rotate(-180deg)' : 'rotate(0)',
                transition: '0.2s',
              }}
            />
            <ListItemText
              primary={"Bill id: DYN-SAAS-" + data.transId + " - Price: " + data.price + "€"}
              secondary="Bill id, Date, Price, Token amount, Billing info"
              secondaryTypographyProps={{
                noWrap: true,
                lineHeight: '16px',
                color: open ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0.5)',
              }}
              sx={{ my: 0 }}
            />
          </ListItemButton>
          <Divider/>
        </Grid>
        {open &&
            <>
                <Grid item xs={12}>
                    <Paper variant="outlined" sx={{height: "60px", padding: 1, paddingLeft: 2, display: "flex", justifyContent: "center"}}>
                        <Typography sx={{flexGrow: 1}} alignSelf="center">Bill id:<br/>DYN-SAAS-{data.transId}</Typography>
                        <Typography sx={{flexGrow: 1}} alignSelf="center">Date:<br/>{new Date(data.date).toUTCString()}</Typography>
                        <Typography sx={{flexGrow: 1}} alignSelf="center">Price:<br/>{data.price} €</Typography>
                        <Typography sx={{flexGrow: 1}} alignSelf="center">Token amount:<br/>{data.amount} tokens</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper variant="outlined" sx={{height: "60px", padding: 1, display: "flex", justifyContent: "center"}}>
                        <Typography sx={{flexGrow: 1}} alignSelf="center">First name:<br/>{data.firstName}</Typography>
                        <Typography sx={{flexGrow: 1}} alignSelf="center">Last name:<br/>{data.lastName}</Typography>
                        <Typography sx={{flexGrow: 1}} alignSelf="center">Country:<br/>{iso3311a2.getCountry(data.country)}</Typography>
                        <Typography sx={{flexGrow: 1}} alignSelf="center">City:<br/>{data.city}</Typography>
                        <Typography sx={{flexGrow: 1}} alignSelf="center">Address:<br/>{data.address}</Typography>
                      { data.companyName !== "" ?  <>
                        <Typography sx={{flexGrow: 1}} alignSelf="center">Company Name:<br/>{data.companyName}</Typography>
                        <Typography sx={{flexGrow: 1}} alignSelf="center">VAT Number:<br/>{data.vatNumber}</Typography>
                        </> : <></> }
                    </Paper>
                </Grid>
            </>
        }
      </Grid>
    </ListItem>
  )
}