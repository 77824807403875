import React from 'react';
import {Accordion, AccordionDetails} from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {YoutubeEmbed} from "./YoutubeEmbed";

export const UnrealVideoTutorialPanel: React.FC = () => {
  return (
    <Accordion disableGutters={true}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
        Unreal Engine Integration
      </AccordionSummary>
      <AccordionDetails sx={{backgroundColor: "background.default", padding: "16px"}}>
        <YoutubeEmbed embedId={"0R5NVermWTo"}/>
      </AccordionDetails>
    </Accordion>
  )
}
