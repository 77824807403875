import {FC} from "react";
import {Grid, List, Typography} from "@mui/material";
import {Result, ResultProps} from "./components/Result";
import background from '../../../../res/background_face.jpg';

interface ResultsPanelProps {
    results: ResultProps[]
}

export const ResultsPanel: FC<ResultsPanelProps> = ({results}) => {
    const temp = results.map((result, index) => <Result
        key={index}
        requestData={result.requestData}
    />)
    return (
        <Grid item xs={12} md={4} sx={{border:1, borderColor:'secondary.main', borderRadius: "4px", backgroundColor: "#030929", backgroundImage:`url(${background})`, backgroundRepeat: "no-repeat", backgroundSize: "150%", backgroundPositionY: "10vh", minHeight: "75vh"}}>
            <Typography align='center' variant='h5' sx={{paddingTop: "16px", paddingBottom: "16px"}}>Resulting animations</Typography>
            <Grid container justifyContent="center" sx={{overflow: 'auto', padding: "16px"}}>
                <List sx={{width: "100%"}}>
                    {temp}
                </List>
            </Grid>
        </Grid>
    )
}
