import DownloadIcon from '@mui/icons-material/Download';
import React, {FC} from "react";
import {Button} from "@mui/material";

interface TextDownloadButtonProps {
  text: string;
  fileName: string;
}

export const TextDownloadButton: FC<TextDownloadButtonProps> = ({text, fileName}) => {
  const handleDownload = () => {
    if (text) {
      const a = document.createElement('a');
      const file = new Blob([text], {type: 'text/plain'});
      a.href = window.URL.createObjectURL(file);
      a.download = fileName;
      a.click();
      return null;
    }
  };

  return (
    <Button sx={{padding: 0, paddingLeft: 1, paddingRight: 1, marginRight: 1}} disabled={text === ""} variant="contained" onClick={handleDownload} startIcon={<DownloadIcon/>}>
      Download
    </Button>
  );
}