import React, {FC, useContext, useEffect, useRef, useState} from "react";
import {UserContext} from "../../../../../../../context/user-context";
import axios from "axios";
import {getErrorMessage, notifyUser} from "../../../../../../../utils/notificationCenter";
import {PlaygroundContext} from "../../../../../../../context/playground-context";
import {useSnackbar} from "notistack";
import {SelectWithLoader} from "../../../../../../../utils/components/SelectWithLoader";
import {loadPlaygroundData, PlaygroundEntry} from "../../../../../../../utils/sessionDataLoader";
import {Box} from "@mui/material";


export type PlaygroundFile = {
    "display": string,
    "language": string,
    "audio": Blob,
    "text": string,
}

const CENTRAL_API_URL = process.env.REACT_APP_CENTRAL_API_URL!
const PLAYGROUND_AUDIO_ROUTE = process.env.REACT_APP_PLAYGROUND_AUDIO_ROUTE!
const PLAYGROUND_TEXT_ROUTE = process.env.REACT_APP_PLAYGROUND_TEXT_ROUTE!

export const PlaygroundSelect: FC = () => {
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const requestSent = useRef<boolean>(false);
    const [entry, setEntry] = useState<PlaygroundEntry>();
    const [entries, setEntries] = useState<PlaygroundEntry[]>([]);
    const [files] = useState<Map<string, PlaygroundFile>>(new Map());
    const [loading, setLoading] = useState<boolean>(false);
    const {playgroundEnabled, setPlaygroundFile} = useContext(PlaygroundContext);
    const {user} = useContext(UserContext);

    useEffect(() => {
        if (playgroundEnabled && entry && !requestSent.current && user) {
            if (files.has(entry.display)) {
                setPlaygroundFile(files.get(entry.display));
            } else {
                setLoading(true);
                axios.post(CENTRAL_API_URL + PLAYGROUND_TEXT_ROUTE, {
                    "jwt": user.jwt,
                    "filepath": entry.display,
                })
                    .then(res => {
                        const text = res.data.text;
                        axios.post(CENTRAL_API_URL + PLAYGROUND_AUDIO_ROUTE, {
                            "jwt": user.jwt,
                            "filepath": entry.display,
                        }, {
                            responseType: 'blob'
                        })
                            .then(res => {
                                const playgroundData = {
                                    audio: res.data,
                                    text: text,
                                    display: entry.display,
                                    language: entry.language
                                };
                                setLoading(false);
                                files.set(entry.display, playgroundData);
                                setPlaygroundFile(playgroundData);
                            })
                            .catch(error => {
                                notifyUser("An error occurred while downloading playground file : " + getErrorMessage(error), true, enqueueSnackbar, closeSnackbar)
                                requestSent.current = false;
                                setLoading(false);
                                setEntry(undefined);
                            })
                    })
                    .catch(error => {
                        notifyUser("An error occurred while downloading playground file : " + getErrorMessage(error), true, enqueueSnackbar, closeSnackbar)
                        requestSent.current = false;
                        setLoading(false);
                        setEntry(undefined);
                    })
                requestSent.current = false;
            }
        } else {
            setPlaygroundFile(undefined);
            setEntry(undefined);
        }
    }, [playgroundEnabled, entries, files, setPlaygroundFile, user, closeSnackbar, enqueueSnackbar, entry]);

    if (playgroundEnabled) {
        return (
          <Box sx={{width: "50%", marginBottom: 1}}>
              <SelectWithLoader id={"playground files"} dataLoaderFunction={loadPlaygroundData}
                                data={entries} setData={setEntries} currentData={entry} setCurrentData={setEntry}
                                loading={loading} setLoading={setLoading}/>
          </Box>
        )
    } else {
        return <></>
    }
}