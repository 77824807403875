import {FC, useContext, useEffect, useRef, useState} from "react";
import {
    Box,
    Grid, LinearProgress, List,
    Paper,
    Typography
} from "@mui/material";
import {AnimationRequest, Request} from "./components/Request";
import {UserContext} from "../../../../context/user-context";
import {getErrorMessage} from "../../../../utils/notificationCenter";
import axios from 'axios';

const CENTRAL_API_URL = process.env.REACT_APP_CENTRAL_API_URL!
const HISTORY_ALL_ROUTE = process.env.REACT_APP_HISTORY_ALL_ROUTE!

export const HistoryBoard: FC = () => {
    const requestSent = useRef<boolean>(false);
    const {user} = useContext(UserContext);
    const [requests, setRequests] = useState<AnimationRequest[]>([]);
    const [totalSize, setTotalSize] = useState<number>(0);

    useEffect(() => {
        let total = 0;
        requests.forEach((history: AnimationRequest) => {
            total += parseInt(history.weight);
        });
        total = total > 1e8 ? 1e8 : total;
        setTotalSize(total / 1e6);
    }, [requests])

    useEffect(() => {
        if (!requestSent.current && user) {
            axios.post(CENTRAL_API_URL + HISTORY_ALL_ROUTE, {
                "jwt": user.jwt
            })
                .then(response => {
                    setRequests(response.data.history)
                })
                .catch(error => console.log("Error on fetching request history from database : ", getErrorMessage(error)))
            requestSent.current = true;
        }
    }, [user])

    return (
        <Box sx={{margin: 2, flexGrow: 1, border: 1, borderRadius: "4px", borderColor:'secondary.main', padding: "16px"}}>
            <Grid container spacing={2} sx={{textAlign: 'center'}} >
                <Grid item xs={12}>
                    <Paper variant="outlined">
                        <Typography variant="h5">Request history</Typography>
                        <Box sx={{display: "flex", justifyContent: 'space-around', alignItems: 'center'}}>
                            Storage usage :
                            <LinearProgress sx={{width: "80%", height: 10, borderRadius: "4px"}} color={totalSize > 80 ? totalSize === 100 ? "error" : "warning" : "secondary"}  variant="determinate" value={totalSize}/>
                            {totalSize.toFixed(2)} / 100MB
                        </Box>
                    </Paper>
                </Grid>
                <Grid container justifyContent="center" sx={{maxHeight: 600, overflow: 'auto', padding: "16px"}}>
                    <List sx={{width: "100%"}}>
                        {requests.map((request: AnimationRequest) => <Request
                            key={request._id}
                            data={request}
                            setRequests={setRequests}
                        />)}
                    </List>
                </Grid>
            </Grid>
        </Box>
    )
}