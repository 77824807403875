import React, {FC, useCallback, useContext, useEffect} from "react";
import {FileInput} from "../../FileInput";
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import {WordType} from "../../../DataInputPanel";
import {PlaygroundContext} from "../../../../../../../../../context/playground-context";

interface TextInputProps {
    requestData: FormData;
    setText: (text: string) => void;
    file: File | undefined;
    setValidationList: (newList: {[word: string]: WordType}) => void;
    setFile: (newFile : File | undefined) => void;
}

export const TextFileInput: FC<TextInputProps> = ({requestData, setText, file, setValidationList, setFile}) => {
    const {playgroundEnabled, playgroundFile} = useContext(PlaygroundContext);

    const fileCallback = useCallback((file: File | undefined) => {
        if (file) {
            requestData.set("textName", file.name);
            const reader = new FileReader()
            reader.onload = async (e) => {
                if (e != null && e.target != null && e.target.result != null) {
                    const text = e.target.result.toString()
                    requestData.set("text", text);
                    setValidationList({})
                    setText(text)
                }
            };
            reader.readAsText(file)
        } else {
            setText("")
            setValidationList({})
        }
    }, [requestData, setText, setValidationList])


    useEffect(() => {
        if (playgroundFile !== undefined && playgroundEnabled) {
            const pFile = new File([playgroundFile.text], playgroundFile.display + ".txt", {
                type: "plain/txt"
            });
            setFile(pFile);
            fileCallback(pFile);
        }
    }, [fileCallback, setFile, playgroundEnabled, playgroundFile]);


    return <FileInput icon={<UploadFileRoundedIcon/>} fileChangeCallback={fileCallback} accept=".txt" file={file} setFile={setFile}/>
}