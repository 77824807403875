function padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
}

export function getCurrentDate() {
    const date = new Date();
    return (
        [
            date.getFullYear(),
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
            padTo2Digits(date.getHours()),
            padTo2Digits(date.getMinutes()),
            padTo2Digits(date.getSeconds()),
        ].join('_')
    );
}