import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, {FC} from "react";

interface TimeStampProps {
    time: number;
    loading: boolean;
}

function toTimeString(time: number) {
    const date = new Date();
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(time);
    return date.toTimeString().slice(3, 8);
}

export const TimeStamp: FC<TimeStampProps> = ({time, loading}) => {
    const defaultTimeStr = "00:00";
    const invalidTimeStr = "--:--";

    const timeStr = Number.isNaN(time) ? invalidTimeStr : toTimeString(time);

    return (
        <Box sx={{minWidth: "50px"}}>
            <Typography>{loading ? defaultTimeStr : timeStr}</Typography>
        </Box>
    );
}