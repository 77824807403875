import {FC, useEffect} from "react";

interface TitleProps {
    text: string;
}

export const Title: FC<TitleProps> = ({text}) => {
    useEffect(() => {
        document.title = text
    }, [text]);

    return <title>={text}</title>
}