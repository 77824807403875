import React, {FC, useEffect, useState} from "react";
import {AssetType, loadAssetData} from "../../../../../utils/sessionDataLoader";
import {searchDataFromLabel, SelectWithLoader} from "../../../../../utils/components/SelectWithLoader";

interface PreferredAssetSelectProps {
  preferredAsset: string,
  setPreferredAsset: (newElem: string) => void,
  disabled: boolean
}

export const PreferredAssetSelect: FC<PreferredAssetSelectProps> = ({preferredAsset, setPreferredAsset, disabled}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [assets, setAssets] = useState<AssetType[]>([]);
  const [currentAsset, setCurrentAsset] = useState<AssetType | undefined>();

  useEffect(() => {
    if (!disabled && currentAsset) {
      setPreferredAsset(currentAsset?.internalLabel);
    }
  }, [disabled, currentAsset, setPreferredAsset]);

  useEffect(() => {
    if (disabled && preferredAsset) {
      setCurrentAsset(searchDataFromLabel(preferredAsset, assets));
    }
  }, [disabled, assets, preferredAsset])

  return (
    <SelectWithLoader id={"preferred asset"} disabled={disabled} dataLoaderFunction={loadAssetData}
                      data={assets} setData={setAssets} currentData={currentAsset} setCurrentData={setCurrentAsset}
                      loading={loading} setLoading={setLoading}/>
  )
}