import React, {useEffect, useState} from "react";
import {CHARTS_BORDER_COLORS, CHARTS_COLORS, Stat} from "../StatisticsBoard";
import {Scatter} from "react-chartjs-2";
import {Chart, Legend, LinearScale, LineElement, PointElement, Title, Tooltip} from "chart.js";
import {Paper, Stack} from "@mui/material";

interface StatsModelChartProps {
    stats: Stat[]
}

interface DataPoint {
    x: number,
    y: number
}

interface Dataset {
    label: string,
    data: DataPoint[],
    borderWidth: number
    borderColor: string,
    backgroundColor: string,
}

interface Datasets {
    datasets : Dataset[]
}

Chart.register(LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

function arrayToDataset(stats: Stat[]) {
    const labels = Array.from(new Set(stats.map(item => item.model)));
    const datasets: Dataset[] = labels.map(model_name => ({
        label: model_name,
        data: [],
        borderWidth: 1,
        borderColor: CHARTS_BORDER_COLORS[labels.indexOf(model_name) % CHARTS_BORDER_COLORS.length],
        backgroundColor: CHARTS_COLORS[labels.indexOf(model_name) % CHARTS_COLORS.length]
    }));
    stats.forEach(item => datasets[labels.indexOf(item.model)].data.push({
        x: item.executionTime / 1000.0,
        y: item.cost,
    }))
    datasets.forEach(dataset => dataset.data.sort((a, b) => a.x - b.x))
    return {
        datasets: datasets
    };
}

export const StatsModelPerfChart: React.FC<StatsModelChartProps> = ({stats}) => {
    const plugin = {
        id: 'customCanvasBackgroundColor',
        beforeDraw: (chart: any, args: any, options: any) => {
            const {ctx} = chart;
            ctx.save();
            ctx.globalCompositeOperation = 'destination-over';
            ctx.fillStyle = "white";
            ctx.fillRect(0, 0, chart.width, chart.height);
            ctx.restore();
        }
    };

    const options = {
        responsive: true,
        maintainAspectRatio : false,
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Tokens cost'
                }
            },
            x: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Execution time (s)'
                }
            },
        },
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: "Model performance with respect to animation length"
            }
        },
    };

    const [datasets, setDatasets] = useState<Datasets>({
        datasets: []
    });

    useEffect(() => {setDatasets(arrayToDataset(stats))}, [stats]);

    return (
        <Paper component={Stack} display="flex" justifyContent="center" sx={{backgroundColor: "white", paddingTop: "16px", paddingBottom: "16px"}}>
            <div style={{position: "relative", height: "70vh", width: "80vw"}}>
                <Scatter options={options} data={datasets} plugins={[plugin]}/>
            </div>
        </Paper>
    )
}