import {createContext} from "react";
import {
    PlaygroundFile
} from "../boards/animation_board/components/control_panel/components/data_input_panel/components/PlaygroundSelect";


export const PlaygroundContext = createContext<{
    playgroundEnabled: boolean;
    playgroundFile: PlaygroundFile | undefined;
    setPlaygroundEnabled: (state: boolean) => void;
    setPlaygroundFile: (state: PlaygroundFile | undefined) => void;
}>({
    playgroundEnabled: false,
    playgroundFile: undefined,
    setPlaygroundEnabled: (_: boolean) => {},
    setPlaygroundFile: (_: PlaygroundFile | undefined) => {},
})

