import {Button} from "@mui/material";
import {OptionsObject, SnackbarKey, SnackbarMessage} from "notistack";

export type EnqueueSnackbar = (message: SnackbarMessage, options?: (OptionsObject | undefined)) => SnackbarKey
export type CloseSnackbar = (key?: (SnackbarKey | undefined)) => void

export function notifyUser(message: string, isError: boolean, enqueueSnackbar: EnqueueSnackbar, closeSnackbar: CloseSnackbar) {
    if (isError) {
        console.log("Logging error for report purpose\n" + message);
    }
    enqueueSnackbar(message, {
        persist: isError,
        variant: isError ? 'error' : 'default',
        action: key => (
            <Button onClick={() => closeSnackbar(key)}>Dismiss</Button>
        )
    })
}

export function getErrorMessage(response: any) {
    try {
        return response.response.data.message;
    } catch (_) {
        return response.message;
    }
    // console.log(response);
}
