import {Box, Button, Divider, List, ListItem, Typography} from "@mui/material";
import React, {FC} from "react";
import Tooltip from "@mui/material/Tooltip";
import ListItemIcon from "@mui/material/ListItemIcon";
import {COLORS} from "./TextValidationInput";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";

export const TextValidationLegend: FC = () => {
    return (
        <>
            <Tooltip arrow title={
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <Box sx={{display: "inline", border: "1px solid black", backgroundColor:COLORS[2], width: "24px", height: "24px"}}/>
                        </ListItemIcon>
                        <Typography sx={{display: "inline"}}>Word pronunciation well recognized</Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <Box sx={{display: "inline", border: "1px solid black", backgroundColor:COLORS[1], width: "24px", height: "24px"}}/>
                        </ListItemIcon>
                        <Typography sx={{display: "inline"}}>Acronym will be pronounced as a sequence of letters</Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <Box sx={{display: "inline", border: "1px solid black", backgroundColor:COLORS[4], width: "24px", height: "24px"}}/>
                        </ListItemIcon>
                        <Typography sx={{display: "inline"}}>Word may have pronunciation errors (check the result of the animation of this word)</Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <Box sx={{display: "inline", border: "1px solid black", backgroundColor:COLORS[3], width: "24px", height: "24px"}}/>
                        </ListItemIcon>
                        <Typography sx={{display: "inline"}}>Word pronunciation unrecognized. Please check our tips to remove potential transcription problem (rewriting)</Typography>
                    </ListItem>
                </List>
            }>
                <Button sx={{padding: 0, paddingLeft: 1, paddingRight: 1, margin: 0, marginRight: 1}} variant="contained" startIcon={<TipsAndUpdatesIcon/>}>
                    Legend
                </Button>
            </Tooltip>
            <Divider orientation={"vertical"} flexItem/>
        </>
    )
}