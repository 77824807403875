import IconButton from "@mui/material/IconButton";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import React, {FC, useContext} from "react";
import {PlaygroundContext} from "../../../../../../../../../../../context/playground-context";

interface PlayPauseButtonProps {
    disabled: boolean;
    audioElement: HTMLAudioElement | null;
    playing: boolean;
}

export const PlayPauseButton: FC<PlayPauseButtonProps> = ({disabled, audioElement, playing}) => {
    const {playgroundEnabled} = useContext(PlaygroundContext);
    const handlePlay = () => {
        if (audioElement) {
            playing ? audioElement?.pause() : audioElement?.play();
            return null;
        }
    };

    return (
        <IconButton disabled={disabled} color="primary" onClick={handlePlay} sx={{p: 0, paddingLeft: playgroundEnabled ? 0 : 1, paddingRight: 1}}>
            {playing ? <PauseIcon /> : <PlayArrowIcon />}
        </IconButton>
    );
}