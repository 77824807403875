import {FC, MouseEvent, useCallback, useContext, useRef, useState} from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import {IconButton} from "@mui/material";
import {getErrorMessage, notifyUser} from "../../../../../utils/notificationCenter";
import {UserContext} from "../../../../../context/user-context";
import {useSnackbar} from "notistack";
import {AnimationRequest} from "./Request";
import axios from 'axios';

export interface HistoryFileDeleteButtonProps {
    self: AnimationRequest,
    setRequests: (callback: ((prevRequests: AnimationRequest[]) => AnimationRequest[])) => void
}

const CENTRAL_API_URL = process.env.REACT_APP_CENTRAL_API_URL!
const HISTORY_DELETE_ROUTE = process.env.REACT_APP_HISTORY_DELETE_ROUTE!

export const HistoryFileDeleteButton: FC<HistoryFileDeleteButtonProps> = ({self, setRequests}) => {
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const [disable, setDisabled] = useState<boolean>(false);
    const requestSent = useRef<boolean>(false);
    const {user} = useContext(UserContext);


    const deleteHandler = useCallback((event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        if (!requestSent.current && user) {
            setDisabled(true);
            notifyUser("Deleting file from server...", false, enqueueSnackbar, closeSnackbar)
            axios.post(CENTRAL_API_URL + HISTORY_DELETE_ROUTE, {
                "jwt": user.jwt,
                "outputFile": self.outputFile,
                "inputs": self.inputs,
                "cost": self.cost,
                "date": self.date,
                "dataPath": self.dataPath
            })
                .then(() => {
                    setRequests((prevRequests: AnimationRequest[]) => {
                        const newRequests = [...prevRequests];
                        const idx = newRequests.indexOf(self);
                        newRequests.splice(idx, 1);
                        // Components are kept by React, this particular request component will see its data changes,
                        // so we need to re-enable the delete button as the layout deletion may not occur on this component
                        requestSent.current = false;
                        setDisabled(false);
                        return newRequests;
                    });
                })
                .catch(error => {
                    notifyUser("An error occurred while deleting file : " + getErrorMessage(error), true, enqueueSnackbar, closeSnackbar)
                    requestSent.current = false;
                    setDisabled(false);
                });
            requestSent.current = true;
        }
    }, [closeSnackbar, enqueueSnackbar, self, setRequests, user]);

    return <IconButton disabled={disable} onClick={deleteHandler} edge="end" aria-label="delete-text" sx={{marginRight: "0px"}}>
        <DeleteIcon/>
    </IconButton>
}