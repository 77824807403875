import React from "react";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {Stat} from "../StatisticsBoard";

interface StatsGridProps {
    stats: Stat[]
}

export const StatsGrid: React.FC<StatsGridProps> = ({stats}) => {
    const rows = stats.map((value, index) => {
        return {
            id: index,
            col0: value.user,
            col1: value.model,
            col2: value.asset,
            col3: new Date(value.date).toUTCString(),
            col4: Math.round(value.executionTime) / 1000,
            col5: value.cost,
            col6: value.mode,
            col7: value.type,
        }
    })

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'Index', width: 100 },
        { field: 'col0', headerName: 'User', width: 200 },
        { field: 'col1', headerName: 'Model', width: 150 },
        { field: 'col2', headerName: 'Asset', width: 150 },
        { field: 'col3', headerName: 'Date', width: 300 },
        { field: 'col4', headerName: 'Execution Time (s)', width: 150 },
        { field: 'col5', headerName: 'Cost', width: 150 },
        { field: 'col6', headerName: 'Mode', width: 150 },
        { field: 'col7', headerName: 'Type', width: 150 },
    ];

    return (
        <div style={{height: "70vh", width: '100%'}}>
             <DataGrid rows={rows} columns={columns}/>
        </div>
    )
}