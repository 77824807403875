import {ChangeEvent, FC, useContext, useEffect, useRef, useState} from "react";
import {
    Box, Button, FormControl, FormHelperText, Grid, MenuItem, Paper, Select,
    Typography
} from "@mui/material";
import {useSnackbar} from "notistack";
import {UserContext} from "../../../../context/user-context";
import {SelectChangeEvent} from "@mui/material/Select";
import {getErrorMessage, notifyUser} from "../../../../utils/notificationCenter";
import axios from 'axios';

const CENTRAL_API_URL = process.env.REACT_APP_CENTRAL_API_URL!
const ID_PROVIDER_API_URL = process.env.REACT_APP_ID_PROVIDER_API_URL!
const ACCOUNTS_ALL_ROUTE = process.env.REACT_APP_ACCOUNTS_ALL_ROUTE!
const GROUP_ALL_ROUTE = process.env.REACT_APP_GROUP_ALL_ROUTE!
const GROUP_ADD_ROUTE = process.env.REACT_APP_GROUP_ADD_ROUTE!
const GROUP_REMOVE_ROUTE = process.env.REACT_APP_GROUP_REMOVE_ROUTE!

type RequestData = {
    "email": string,
    "group": string,
}

type AccountData = {
    email: string;
}

export const ManageGroupsBoard: FC = () => {
    const {user, validUser} = useContext(UserContext);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const [account, setAccount] = useState<AccountData[]>([]);
    const [group, setGroup] = useState<string[]>([]);
    const requestSent = useRef<boolean>(false);
    const [requestData, setRequestData] = useState<RequestData>({
        email: "",
        group: "",
    });

    const dataHandler = (event: ChangeEvent | SelectChangeEvent) => {
        const {name, value} = event.target as HTMLInputElement;
        setRequestData({
            ...requestData,
            [name]: value
        })
    }

    useEffect(() => {
        if (!requestSent.current && user) {
            axios.post(ID_PROVIDER_API_URL + ACCOUNTS_ALL_ROUTE, {
                "jwt": user.jwt,
            })
                .then(res => {
                    setAccount(res.data.accounts);
                    requestData.email = user.email;
                })
                .catch(error => console.log("Error on fetching accounts from database : ", getErrorMessage(error)))
            axios.post(CENTRAL_API_URL + GROUP_ALL_ROUTE, {
                "jwt": user.jwt,
            })
                .then(res => setGroup(res.data.groups))
                .catch(error => console.log("Error on fetching groups : ", getErrorMessage(error)))
            requestSent.current = true;
        }
    }, [requestData, user]);

    const items: JSX.Element[] = account.map((value, index) => {
        return <MenuItem key={index} value={value.email}>{value.email}</MenuItem>
    })

    const grps: JSX.Element[] = group.map((value, index) => {
        return <MenuItem key={index} value={value}>{value}</MenuItem>
    })

    const requestHandler = () => {
        validUser(user);
        if (user?.perm === "ADMIN") {
            axios.post(CENTRAL_API_URL + GROUP_ADD_ROUTE, {
                "jwt": user.jwt,
                ...requestData
            })
                .then(() => notifyUser("Account " + requestData.email + " was added to the group " + requestData.group, false, enqueueSnackbar, closeSnackbar))
                .catch(error => notifyUser("An error occurred while adding the account to the group : " + getErrorMessage(error), true, enqueueSnackbar, closeSnackbar));
        } else {
            notifyUser("You must be a connected administrator to do this.", true, enqueueSnackbar, closeSnackbar)
        }
    }

    const requestRemoveHandler = () => {
        validUser(user);
        if (user?.perm === "ADMIN") {
            axios.post(CENTRAL_API_URL + GROUP_REMOVE_ROUTE, {
                "jwt": user.jwt,
                ...requestData
            })
                .then(() => notifyUser("Account " + requestData.email + " was removed from the group " + requestData.group, false, enqueueSnackbar, closeSnackbar))
                .catch(error => notifyUser("An error occurred while removing the account from the group : " + getErrorMessage(error), true, enqueueSnackbar, closeSnackbar));
        } else {
            notifyUser("You must be a connected administrator to do this.", true, enqueueSnackbar, closeSnackbar)
        }
    }

    return (
            <Box sx={{
                margin: 2,
                flexGrow: 1,
                border: 1,
                borderRadius: "4px",
                borderColor: 'secondary.main',
                padding: "16px"
            }}>
                <form>
                    <Grid container spacing={2} sx={{textAlign: 'center'}}>
                        <Grid item xs={12}>
                            <Paper variant="outlined">
                                <Typography variant="h5">Manage account groups form</Typography>
                            </Paper>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Paper variant="outlined"
                                   sx={{display: "flex", justifyContent: "space-around", paddingRight: 2}}>
                                <Typography sx={{flexGrow: 1}} alignSelf="center">Account :</Typography>
                                <FormControl sx={{flexGrow: 2}}>
                                    <Select onChange={dataHandler} value={requestData.email} name="email" variant="standard"
                                            id="account-email" aria-describedby="account-email-helper">
                                        {items}
                                    </Select>
                                    <FormHelperText id="account-email-helper">The account email</FormHelperText>
                                </FormControl>
                            </Paper>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Paper variant="outlined"
                                   sx={{display: "flex", justifyContent: "space-around", paddingRight: 2}}>
                                <Typography sx={{flexGrow: 1}} alignSelf="center">Group :</Typography>
                                <FormControl sx={{flexGrow: 2}}>
                                    <Select onChange={dataHandler} value={requestData.group} name="group"
                                            variant="standard" id="groups-group" aria-describedby="groups-group-helper">
                                        {grps}
                                    </Select>
                                    <FormHelperText id="groups-group-helper">The group</FormHelperText>
                                </FormControl>
                            </Paper>
                        </Grid>

                        <Grid item xs={12} sx={{margin: 2}}>
                            <Button onClick={requestHandler} sx={{fontSize: "1em", margin: 1}} variant="contained">
                                <Typography sx={{textAlign: "center"}}>Add account to group</Typography>
                            </Button>
                            <Button onClick={requestRemoveHandler} sx={{fontSize: "1em", margin: 1}} variant="contained">
                                <Typography sx={{textAlign: "center"}}>Remove account from group</Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
    )
}