import React, {FC, useContext, useState} from "react";
import {Box, Button, Divider, Paper, Typography} from "@mui/material";
import {VerifyButton} from "./components/VerifyButton";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import {WordType} from "../../DataInputPanel";
import ClearIcon from '@mui/icons-material/Clear';
import NotesIcon from "@mui/icons-material/Notes";
import {TextFileInput} from "./components/TextFileInput";
import {TextValidationLegend} from "./components/TextValidationLegend";
import {TextTips} from "./components/TextTips";
import {SendContext} from "../../../../../../../../context/send-context";
import {PlaygroundContext} from "../../../../../../../../context/playground-context";
import {TextDownloadButton} from "./components/TextDownloadButton";

interface TextControlsProps {
    text: string,
    validText: string[],
    setText: (newText: string) => void,
    setValidationList: (newList: {[word: string]: WordType}) => void,
    setValidText: (newText: string[]) => void,
    requestData: FormData;
}


export const TextControls: FC<TextControlsProps> = ({text, validText, setText, setValidationList, setValidText, requestData}) => {
    const [file, setFile] = useState<File | undefined>(undefined);
    const {setTextProvided} = useContext(SendContext);
    const {playgroundEnabled} = useContext(PlaygroundContext);

    const resetTextHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        if (file !== undefined) {
            const reader = new FileReader()
            reader.onload = async (e) => {
                if (e != null && e.target != null && e.target.result != null) {
                    const text = e.target.result.toString()
                    setValidationList({})
                    setText(text)
                }
            };
            reader.readAsText(file)
        }
    }

    const clearTextHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        setText("")
        setTextProvided(false)
        setValidText([])
        setValidationList({})
    }

    return (
        <Paper sx={{p: 1, paddingLeft: 2, width: "100%", display: 'inline-flex', borderRadius: 2, borderBottomRightRadius:0, borderBottomLeftRadius: 0, alignItems: "center", justifyContent: "space-evenly"}}>
            <TextTips/>
            <NotesIcon/><Typography variant="body1" sx={{marginLeft: 1}}>Text</Typography>
            <Box sx={{flexGrow: 1}}/>
            {validText.length ? <TextValidationLegend/> : <></>}
            <VerifyButton setValidationList={setValidationList} setValidText={setValidText} requestData={requestData}/>
            {playgroundEnabled && file ? <TextDownloadButton text={text} fileName={file.name}/> : <></>}
            <Button disabled={playgroundEnabled || file === undefined} sx={{padding: 0, paddingLeft: 1, paddingRight: 1, margin: 0, marginRight: 1}} onClick={resetTextHandler} variant="contained" startIcon={<RestartAltIcon/>}>
                Reload
            </Button>
            <Button disabled={playgroundEnabled || (text === "" && validText.length === 0)} sx={{padding: 0, paddingLeft: 1, paddingRight: 1, margin: 0, marginRight: 1}} onClick={clearTextHandler} variant="contained" startIcon={<ClearIcon/>}>
                Clear
            </Button>
            <Divider orientation={"vertical"} flexItem />
            <TextFileInput requestData={requestData} setText={setText} file={file} setValidationList={setValidationList} setFile={setFile}/>
        </Paper>
    )
}
