import {FC, useCallback, useContext, useEffect, useRef, useState} from "react";
import {Avatar, CircularProgress, IconButton, ListItem, ListItemAvatar, ListItemText} from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import ErrorIcon from '@mui/icons-material/Error';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import {SendContext} from "../../../../../context/send-context";
import {getErrorMessage, notifyUser} from "../../../../../utils/notificationCenter";
import {useSnackbar} from "notistack";
import {UserContext} from "../../../../../context/user-context";
import axios from 'axios';
import {PlaygroundContext} from "../../../../../context/playground-context";

export interface ResultProps {
    requestData: FormData
}

const CENTRAL_API_URL = process.env.REACT_APP_CENTRAL_API_URL!
const PROCESS_ROUTE = process.env.REACT_APP_PROCESS_ROUTE!
const PLAYGROUND_PROCESS_ROUTE = process.env.REACT_APP_PLAYGROUND_PROCESS_ROUTE!

export const Result: FC<ResultProps> = ({requestData}) => {
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const [response, setResponse] = useState<any | undefined>(undefined);
    const [requestAlive, setRequestAlive] = useState<boolean>(true);
    const {setSpammingProtection} = useContext(SendContext);
    const requestSent = useRef<boolean>(false);
    const {user, validUser} = useContext(UserContext);
    const {playgroundEnabled, playgroundFile} = useContext(PlaygroundContext);

    const downloadHandler = useCallback(() => {
        const url = window.URL.createObjectURL(new Blob([response.filedata]));
        const a = document.createElement('a');
        a.href = url;
        a.download = response.filename;
        a.click();
    }, [response]);

    useEffect(() => {
        if (!requestSent.current) {
            notifyUser("Sending animation request to server...", false, enqueueSnackbar, closeSnackbar);
            const usePlaygroundRoute = user && playgroundEnabled && playgroundFile;
            axios.post(
                CENTRAL_API_URL + (usePlaygroundRoute ? PLAYGROUND_PROCESS_ROUTE : PROCESS_ROUTE),
                usePlaygroundRoute ? {jwt: user.jwt, file: playgroundFile.display, asset: requestData.get("asset")} : requestData,
                usePlaygroundRoute ? {} : {
                headers: {"Content-Type": "multipart/form-data"}
            })
                .then(res => {
                    setSpammingProtection(false)
                    setResponse({
                        filedata: res.data.animation,
                        filename: res.data.filename
                    })
                    validUser(user)
                })
                .catch(error => {
                    notifyUser("An error occurred while animating data : " + getErrorMessage(error), true, enqueueSnackbar, closeSnackbar)
                    setRequestAlive(false);
                    setSpammingProtection(false)
                });
            requestSent.current = true;
        }
    }, [closeSnackbar, enqueueSnackbar, playgroundEnabled, playgroundFile, requestData, setSpammingProtection, user, validUser])

    if (response === undefined) {
        let inner;
        if (requestAlive) {
            inner = <>
                <ListItemAvatar>
                    <CircularProgress color="secondary"/>
                </ListItemAvatar>
                <ListItemText primary="Processing..."/>
            </>
        } else {
            inner = <>
                <ListItemAvatar sx={{justifyContent: "center", display: "flex" }}>
                    <ErrorIcon/>
                </ListItemAvatar>
                <ListItemText primary="Your request failed due to a server error, please report the notification error to an administrator"/>
            </>
        }
        return (
            <ListItem sx={{backgroundColor: "#19184fCC", borderRadius: "8px", marginBottom: "16px"}}>
                {inner}
            </ListItem>
        )
    } else {
        return (
            <ListItem
                secondaryAction={(
                    <IconButton onClick={downloadHandler} edge="end" aria-label="download" sx={{marginRight: "0px"}}>
                        <DownloadIcon/>
                    </IconButton>
                    )} sx={{backgroundColor: "#340b64CC", borderRadius: "8px", marginBottom: "16px"}}>
                <ListItemAvatar>
                    <Avatar>
                        <FileDownloadDoneIcon/>
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={"Result : " + response.filename}/>
            </ListItem>
        )
    }
}