import React, {FC} from 'react';
import MenuItem from '@mui/material/MenuItem';
import {PersonalInfos} from "../../../../../user/infosProvider";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import {FormHelperText} from "@mui/material";

const iso3311a2 = require('iso-3166-1-alpha-2');

interface CountrySelectProps {
    visibleInfos: PersonalInfos
    setVisibleInfos: (newElem: any) => void
    disabled?: boolean
}

export const CountrySelect: FC<CountrySelectProps> = ({visibleInfos, setVisibleInfos, disabled}) => {
    const disabledCountries = [
      "AF",
      "BY",
      "CF",
      "KP",
      "IQ",
      "IR",
      "LB",
      "SO",
      "SS",
      "SD",
      "SY",
      "UA",
      "YE",
      "ZW",
      "LY",
      "RU",
      "CD",
      "MM"
    ]
    const items: JSX.Element[] = iso3311a2.getCountries().reduce((results: JSX.Element[], value: any, index: any) => {
        if (!disabledCountries.includes(iso3311a2.getCode(value))) {
            results.push(<MenuItem key={index} value={iso3311a2.getCode(value)}>{value}</MenuItem>)
        }
        return results
    }, []);

    const onChange = (event: SelectChangeEvent) => {
        setVisibleInfos({
            ...visibleInfos,
            country: event.target.value,
        });
    }

    return (
      <>
        <Select
          id={"country-select"}
          disabled={disabled}
          error={visibleInfos.country === ""}
          value={visibleInfos.country}
          onChange={onChange}
          sx={{width: "100%", height: "100%"}}
          size="small"
        >
            {items}
        </Select>
        {
          visibleInfos.country === "" ?
            <FormHelperText error>Please provide your country.</FormHelperText> : <></>
        }
      </>
    );
}