import React, {useContext, useEffect, useState} from 'react';
import MenuItem from "@mui/material/MenuItem";
import {capitalize, CircularProgress, Paper} from "@mui/material";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import InputLabel from "@mui/material/InputLabel";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import {User, UserContext} from "../../context/user-context";
import Box from "@mui/material/Box";

export type BaseData = {
  internalLabel: string,
  display: string,
};

interface SelectWithLoaderProps<T extends BaseData> {
  id: string,
  disabled?: boolean,
  dataLoaderFunction: (user: User, setLoading: (newState: boolean) => void, setData: (newData: T[]) => void, setCurrentData: (newElem: T | undefined) => void, setDefault: boolean) => void;
  data: T[],
  setData: (newData: T[]) => void,
  currentData: T | undefined,
  setCurrentData: (newCurrentDataLabel: T | undefined) => void,
  loading: boolean,
  setLoading: (newState: boolean) => void,
  replaceCurrentOnLoad?: boolean,
  dataVisibility?: boolean[],
}

export function searchDataFromDisplay<T extends BaseData>(display: string, datas: T[]): T {
  for (let data of datas) {
    if (data.display === display) {
      return data;
    }
  }
  throw new Error("Error during search from display value : " + display + ", please report to an administrator")
}

export function searchDataFromLabel<T extends BaseData>(label: string, datas: T[]): T {
  for (let data of datas) {
    if (data.internalLabel === label) {
      return data;
    }
  }
  throw new Error("Error during search from label value : " + label + ", please report to an administrator")
}

export const SelectWithLoader = <T extends BaseData,>({id, disabled, dataLoaderFunction, data, setData, currentData, setCurrentData, loading, setLoading, replaceCurrentOnLoad, dataVisibility}: SelectWithLoaderProps<T>) => {
  const [label, setLabel] = useState<string>("");
  const [lastDataUser, setLastDataUser] = useState<string>("");
  const {user} = useContext(UserContext);

  useEffect(() => {
    // User data are reloaded on each animation/verification,
    // without this email check this would reload all data from session cache
    // but will trigger a lot of hook as it will treat those data as different references
    if (user && lastDataUser !== user.email) {
      dataLoaderFunction(user, setLoading, setData, setCurrentData, !!replaceCurrentOnLoad);
      setLastDataUser(user.email);
    } else {
      setLoading(false);
    }
  }, [lastDataUser, user, dataLoaderFunction, setData, setCurrentData, replaceCurrentOnLoad]);

  const items = data.map((value, index) => {
    return <MenuItem key={index} value={value.display} disabled={dataVisibility && dataVisibility.length > index && !dataVisibility[index]}>{value.display}</MenuItem>
  })

  const onChange = (event: SelectChangeEvent) => {
    setCurrentData(searchDataFromDisplay(event.target.value, data));
  }

  useEffect(() => {
    if (currentData) {
      setLabel(currentData.display);
    }
  }, [currentData])

  let innerComponent;

  if (loading) {
    innerComponent = <CircularProgress sx={{marginLeft: "64px"}} />;
  } else {
    if (data.length === 0) {
      innerComponent = <ReportProblemIcon sx={{marginLeft: "64px"}}  color={"error"}/>
    } else {
      innerComponent = <Select
          id={id}
          disabled={disabled}
          value={label}
          onChange={onChange}
          sx={{width: "100%", height: "100%"}}
          size="small"
        >
          {items}
        </Select>
    }
  }

  return (
    <Paper variant="outlined" sx={{width: "100%", borderRadius: 2, backgroundColor: "background.default", display: "inline-flex", alignItems: "center", justifyContent: "space-around"}}>
      <InputLabel sx={{marginLeft: 2, marginRight: 2}} id={id}>{capitalize(id)} :</InputLabel>
      <Box sx={{flexGrow: 1}}>
        {innerComponent}
      </Box>
    </Paper>
  )
}