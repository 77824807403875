import React from 'react';
import {Accordion, AccordionDetails, List, ListItem, ListItemText, Typography} from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const WebTextTutorialPanel: React.FC = () => {
  return (
      <Accordion disableGutters={true} defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          Web platform Tutorial
        </AccordionSummary>
        <AccordionDetails sx={{backgroundColor: "background.default", padding: "16px"}}>
          <Typography>Here's a little tutorial step by step on how to use the platform :</Typography>
          <List>
            <ListItem>
              <ListItemText primary={"Step 1."} secondary={"Pick your animation language. The language should be the main spoken language in the audio/text, if your language is not supported, please contact us."}/>
            </ListItem>
            <ListItem>
              <ListItemText primary={"Step 2."} secondary={"Upload an audio file and the corresponding text transcription you want to animate."}/>
            </ListItem>
            <ListItem>
              <ListItemText primary={"Step 3."} secondary={"Verify your text using the text validation tools, it will tell you if words won't be recognised."}/>
            </ListItem>
            <ListItem>
              <ListItemText primary={"Step 4."} secondary={"Make sure all animation parameters fulfill your case :"}/>
            </ListItem>
            <ListItem>
              <List disablePadding>
                <ListItem dense sx={{paddingTop: 0, paddingBottom: 0}}>
                  <ListItemText secondary={"The asset corresponds to the avatar that you want to animate, this allows the .fbx file to contain the avatar skeleton hierarchy."}/>
                </ListItem>
                <ListItem dense sx={{paddingTop: 0, paddingBottom: 0}}>
                  <ListItemText secondary={"The engine is the targeted rendering environment, as the output is a .fbx multiple engines are supported but we specifically make sure that engines from the list are viable."}/>
                </ListItem>
              </List>
            </ListItem>
            <ListItem>
              <ListItemText primary={"Step 5."} secondary={"The platform will tell you the cost of animation depending on the audio file, make sure you have enough tokens."}/>
            </ListItem>
            <ListItem>
              <ListItemText primary={"Step 6."} secondary={"Click on animate and wait for our platform to make its magic ! Your animation file will soon be available, afterwards you can find it back in your Account animation history."}/>
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
  )
}
