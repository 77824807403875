import {FC, useEffect, useState} from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {HandleConnection} from "./boards/HandleConnection";
import {getCookie, removeCookie} from 'typescript-cookie'
import {User, UserContext} from "./context/user-context";
import {HeadBar} from "./HeadBar";
import {AnimationBoard} from "./boards/animation_board/AnimationBoard";
import {SnackbarProvider} from "notistack";
import {HelpBoard} from "./boards/help_board/HelpBoard";
import {provideUserInfos} from "./user/infosProvider";
import {NotFoundBoard} from "./boards/not_found_board/NotFoundBoard";
import {AdministrationBoard} from "./boards/administration_board/AdministrationBoard";
import {AccountBoard} from "./boards/account_board/AccountBoard";
import {Footer} from "./Footer";

const ID_PROVIDER_FRONT_URL = process.env.REACT_APP_ID_PROVIDER_FRONT_URL!
const REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL!

const App: FC = () => {
    const [user, setUser] = useState<User | undefined>(undefined);
    useEffect(() => {
        const userCookie = getCookie('jwt');
        if (userCookie) {
            loadUserInfos(userCookie).then()
        } else {
            sessionStorage.clear();
            window.location.href = ID_PROVIDER_FRONT_URL + "?redirect=" + REDIRECT_URL
        }
    }, [])

    const validUser = (user: User | undefined) => {
        loadUserInfos(user?.jwt).then()
    }
    const loadUserInfos = async (jwt: string | undefined) => {
        if (jwt) {
            const infos = await provideUserInfos(jwt)
            if (!infos) {
                sessionStorage.clear();
                removeCookie("jwt")
                window.location.href = ID_PROVIDER_FRONT_URL + "?redirect=" + REDIRECT_URL
            } else {
                setUser(infos)
            }
        } else {
            sessionStorage.clear();
            window.location.href = ID_PROVIDER_FRONT_URL + "?redirect=" + REDIRECT_URL
        }
    }
    return (
        <SnackbarProvider
            maxSnack={4}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
        >
            <UserContext.Provider value={{
                user: user,
                setUser: setUser,
                validUser: validUser
            }}>
                <BrowserRouter>
                    <HeadBar/>
                    <Routes>
                        <Route path="/" element={<AnimationBoard />} />
                        <Route path="/admin/*" element={<AdministrationBoard/>}/>
                        <Route path="/account/*" element={<AccountBoard/>}/>
                        <Route path="/help" element={<HelpBoard />} />
                        <Route path="/handle-connection" element={<HandleConnection />} />
                        <Route path="/*" element={<NotFoundBoard />} />
                    </Routes>
                    <Footer/>
                </BrowserRouter>
            </UserContext.Provider>
        </SnackbarProvider>

    )
}

export default App;
