import {ChangeEvent, FC, useContext, useEffect, useRef, useState} from "react";
import {
    Box, Button,
    FormControl,
    FormHelperText,
    Grid, MenuItem,
    Paper, Select,
    TextField,
    Typography
} from "@mui/material";
import {SelectChangeEvent} from "@mui/material/Select";
import {getErrorMessage, notifyUser} from "../../../../utils/notificationCenter";
import {UserContext} from "../../../../context/user-context";
import {useSnackbar} from "notistack";
import axios from 'axios';

type ReloadAccountForm = {
    "email": string,
    "tokens": number,
}

type AccountData = {
    "email": string
}

const ID_PROVIDER_API_URL = process.env.REACT_APP_ID_PROVIDER_API_URL!
const ACCOUNTS_ALL_ROUTE = process.env.REACT_APP_ACCOUNTS_ALL_ROUTE!
const ACCOUNTS_RELOAD_ROUTE = process.env.REACT_APP_ACCOUNTS_RELOAD_ROUTE!

export const ReloadAccountBoard: FC = () => {
    const {user, validUser} = useContext(UserContext);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const [accounts, setAccounts] = useState<AccountData[]>([]);
    const requestSent = useRef<boolean>(false);
    const [requestData, setRequestData] = useState<ReloadAccountForm>({
        email: "",
        tokens: 0,
    });

    useEffect(() => {
        if (!requestSent.current && user) {
            axios.post(ID_PROVIDER_API_URL + ACCOUNTS_ALL_ROUTE, {
                "jwt": user.jwt,
            })
                .then(res => {
                    setAccounts(res.data.accounts);
                    requestData.email = user.email;
                })
                .catch(error => console.log("Error on fetching accounts from database : ", getErrorMessage(error)))
            requestSent.current = true;
        }
    }, [requestData, user]);

    const items: JSX.Element[] = accounts.map((value, index) => {
        return <MenuItem key={index} value={value.email}>{value.email}</MenuItem>
    })

    const dataHandler = (event: ChangeEvent | SelectChangeEvent) => {
        const {name, value} = event.target as HTMLInputElement;
        setRequestData({
            ...requestData,
            [name]: value
        })
    }

    const requestHandler = () => {
        validUser(user);
        if (user?.perm === "ADMIN") {
            axios.post(ID_PROVIDER_API_URL + ACCOUNTS_RELOAD_ROUTE, {
                "jwt": user.jwt,
                ...requestData
            })
                .then(() => notifyUser("Account " + requestData.email + " effectively reloaded.", false, enqueueSnackbar, closeSnackbar))
                .catch(error => notifyUser("An error occurred while reloading account : " + getErrorMessage(error), true, enqueueSnackbar, closeSnackbar));
        } else {
            notifyUser("You must be a connected administrator to do this.", true, enqueueSnackbar, closeSnackbar)
        }
    }

    return (
        <Box sx={{margin: 2, flexGrow: 1, border: 1, borderRadius: "4px", borderColor:'secondary.main', padding: "16px"}}>
            <form>
                <Grid container spacing={2} sx={{textAlign: 'center'}} >
                    <Grid item xs={12}>
                        <Paper variant="outlined">
                            <Typography variant="h5">Reload user tokens form</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper variant="outlined" sx={{display: "flex", justifyContent: "space-around", paddingRight: 2}}>
                            <Typography sx={{flexGrow: 1}} alignSelf="center">Account to reload :</Typography>
                            <FormControl sx={{flexGrow: 2}}>
                                <Select onChange={dataHandler} value={requestData.email} name="email" variant="standard" id="account-email" aria-describedby="account-email-helper">
                                    {items}
                                </Select>
                                <FormHelperText id="account-email-helper">The account email</FormHelperText>
                            </FormControl>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper variant="outlined" sx={{display: "flex", justifyContent: "space-around", paddingRight: 2}}>
                            <Typography sx={{flexGrow: 1}} alignSelf="center">Tokens to add :</Typography>
                            <FormControl sx={{flexGrow: 2}}>
                                <TextField value={requestData.tokens} name="tokens"  variant="standard" type="number" onChange={(event : ChangeEvent<HTMLInputElement>) => {
                                    const value = +event.target.value
                                    if (value < 0) {
                                        event.target.value = "0"
                                    }
                                    dataHandler(event)
                                }} id="account-tokens" aria-describedby="account-tokens-helper" helperText="The amount of tokens to add"/>
                            </FormControl>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sx={{margin: 2}}>
                        <Button onClick={requestHandler} sx={{fontSize: "1em"}} variant="contained">
                            <Typography sx={{textAlign: "center"}}>Reload account</Typography>
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Box>
    )
}
