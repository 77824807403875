import React, {ChangeEvent, FC, SyntheticEvent, useContext, useEffect, useRef, useState} from "react";
import {
    Box, Button, capitalize, Checkbox, Divider, Grid, Link, Paper, Stack, TextField, Typography,
} from "@mui/material";
import {UserContext} from "../../../../context/user-context";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import InfoIcon from "@mui/icons-material/Info";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AccordionDetails from "@mui/material/AccordionDetails";
import axios from 'axios';
import {getErrorMessage, notifyUser} from "../../../../utils/notificationCenter";
import {useSnackbar} from "notistack";
import {EuroIcon} from "../../../../utils/components/EuroIcon";
import {BillingInfo, BillingInfoForm} from "./components/BillingInfoForm";

const PAYMENT_URL = process.env.REACT_APP_PAYMENT_URL!
const CENTRAL_API_URL = process.env.REACT_APP_CENTRAL_API_URL!
const PAYMENT_REGISTER_ROUTE = process.env.REACT_APP_PAYMENT_REGISTER_ROUTE!
const PAYMENT_PRICING_ROUTE = process.env.REACT_APP_PAYMENT_PRICING_ROUTE!


export const PaymentBoard: FC = () => {
    const minAmount = 10;
    const [expanded, setExpanded] = React.useState<string>('panel1');
    const {user} = useContext(UserContext);
    const requestSent = useRef<boolean>(false);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const [tokenAmount, setTokenAmount] = useState<number>(minAmount);

    const [infos, setInfos] = useState<BillingInfo>({
        address: "", city: "", companyName: "", country: "", firstName: "", lastName: "", vatNumber: ""
    });
    const [checked, setChecked] = useState<boolean>(false);
    const [rightChecked, setRightChecked] = useState<boolean>(false);
    const [keepInfo, setKeepInfo] = useState<boolean>(false);

    const amountTag = useRef<HTMLInputElement>(null);
    const signatureTag = useRef<HTMLInputElement>(null);
    const dateTag = useRef<HTMLInputElement>(null);
    const transIdTag = useRef<HTMLInputElement>(null);
    const siteIdTag = useRef<HTMLInputElement>(null);
    const countryTag = useRef<HTMLInputElement>(null);
    const emailTag = useRef<HTMLInputElement>(null);
    const firstNameTag = useRef<HTMLInputElement>(null);
    const lastNameTag = useRef<HTMLInputElement>(null);
    const cityTag = useRef<HTMLInputElement>(null);
    const addressTag = useRef<HTMLInputElement>(null);

    const formTag = useRef<HTMLFormElement>(null);

    const [amount, setAmount] = useState<number>(0);
    const [price, setPrice] = useState<number>(0);

    const handleExpandedChange = (panel: string) => (event: SyntheticEvent, newState: boolean) => {
          setExpanded(newState ? panel : '');
      };

    useEffect(() => {
        if (!requestSent.current && user) {
            axios.post(
              CENTRAL_API_URL + PAYMENT_PRICING_ROUTE,
              { jwt: user.jwt }
              )
              .then(res => {
                  setPrice(res.data.price);
                  setAmount(Math.round((tokenAmount * res.data.price + Number.EPSILON) * 100));
              })
              .catch(error => {
                  notifyUser("An error occurred while fetching pricing : " + getErrorMessage(error), true, enqueueSnackbar, closeSnackbar);
              });
            requestSent.current = true;
        }
    }, [closeSnackbar, enqueueSnackbar, tokenAmount, user]);

    const handleAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = +event.target.value
        if (value < minAmount) {
            event.target.value = "" + minAmount;
        }
        setTokenAmount(+event.target.value);
        setAmount(Math.round((+event.target.value * price + Number.EPSILON) * 100));
    };

    const registerPayment = () => {
        if (user) {
            axios.post(
              CENTRAL_API_URL + PAYMENT_REGISTER_ROUTE,
              {
                  jwt: user?.jwt,
                  tokenAmount: tokenAmount,
                  infos: infos,
                  keepInfo: keepInfo
              })
              .then(res => {
                  if (signatureTag.current && dateTag.current && transIdTag.current && siteIdTag.current && amountTag.current && countryTag.current && formTag.current && emailTag.current && firstNameTag.current && lastNameTag.current && cityTag.current && addressTag.current) {
                      signatureTag.current.value = res.data.signature;
                      dateTag.current.value = res.data.date;
                      transIdTag.current.value = res.data.transId;
                      siteIdTag.current.value = res.data.siteId;
                      amountTag.current.value = res.data.amount;
                      countryTag.current.value = res.data.country;
                      cityTag.current.value = res.data.city;
                      addressTag.current.value = res.data.address;
                      emailTag.current.value = user.email;
                      firstNameTag.current.value = res.data.firstName;
                      lastNameTag.current.value = res.data.lastName;
                      formTag.current.submit();
                  } else {
                      notifyUser("An error occurred while registering payment data : Form is undefined", true, enqueueSnackbar, closeSnackbar);
                  }
              })
              .catch(error => {
                  notifyUser("An error occurred while registering payment data : " + getErrorMessage(error), true, enqueueSnackbar, closeSnackbar);
              });
        }
    };

    return (
        <Box sx={{margin: 2, flexGrow: 1, border: 1, borderRadius: "4px", borderColor:'secondary.main', padding: "16px"}}>
            <Grid container spacing={2} sx={{textAlign: 'left', marginBottom: 1}} >
                <Grid item xs={12}>
                    <Paper variant="outlined" sx={{textAlign: 'center'}} >
                        <Typography variant="h5">Purchase tokens</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Accordion onChange={handleExpandedChange('panel1')} expanded={expanded === 'panel1'} sx={{
                                backgroundColor: "transparent",
                                backgroundImage: "none",
                                boxShadow: 0
                            }}>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1-content"
                                  id="panel1-header"
                                >
                                    <Stack direction="row" alignItems="center" gap={1} sx={{marginTop: 0, marginBottom: 0}}>
                                        <ShoppingCartIcon/>
                                        <Typography variant="h6">Purchase infos</Typography>
                                    </Stack>
                                </AccordionSummary>
                                <AccordionDetails sx={{marginLeft: 2, marginRight: 2, borderTop: 1, borderColor: 'rgba(255, 255, 255, 0.12)'}}>
                                    <Grid container>
                                        <Grid item xs={12} sx={{textAlign: 'left'}}>
                                            <Stack direction="row" alignItems="center" gap={1}>
                                                <Typography variant="h6">Tokens to buy</Typography>
                                                <Typography variant="caption">Minimum is 10</Typography>
                                            </Stack>
                                            <Paper variant={"outlined"}  sx={{marginTop: 0.5, marginBottom: 1}}>
                                                <TextField type={"number"} value={tokenAmount} onChange={handleAmountChange} fullWidth size="small"/>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={5} sx={{textAlign: 'left'}}>
                                            <Stack direction="row" alignItems="center" justifyContent="center" gap={1}>
                                                <Typography variant="h6">Amount before</Typography>
                                            </Stack>
                                            <Paper variant={"outlined"}  sx={{marginTop: 0.5, marginBottom: 1}}>
                                                <TextField value={user?.tokens} disabled fullWidth size="small"/>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={2} sx={{textAlign: 'center', display: 'inline', verticalAlign: 'middle'}}>
                                            <Stack direction="row" alignItems="center" justifyContent="center" gap={1}>
                                                <Typography variant="h6">&nbsp;</Typography>
                                            </Stack>
                                            <ArrowForwardIcon sx={{marginTop: 1.75}}/>
                                        </Grid>
                                        <Grid item xs={5} sx={{textAlign: 'left'}}>
                                            <Stack direction="row" alignItems="center" justifyContent="center" gap={1}>
                                                <Typography variant="h6">Amount after</Typography>
                                            </Stack>
                                            <Paper variant={"outlined"}  sx={{marginTop: 0.5, marginBottom: 1}}>
                                                <TextField value={user ? user.tokens + tokenAmount : tokenAmount} disabled fullWidth size="small"/>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider orientation="horizontal" variant="middle" flexItem/>
                            <Accordion onChange={handleExpandedChange('panel2')} expanded={expanded === 'panel2'} sx={{
                                backgroundColor: "transparent",
                                backgroundImage: "none",
                                boxShadow: 0
                            }}>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel2-content"
                                  id="panel2-header"
                                >
                                    <Stack direction="row" alignItems="center" gap={1} sx={{marginTop: 0, marginBottom: 0}}>
                                        <InfoIcon/>
                                        <Typography variant="h6">Billing infos</Typography>
                                    </Stack>
                                </AccordionSummary>
                                <AccordionDetails sx={{marginLeft: 2, marginRight: 2, borderTop: 1, borderColor: 'rgba(255, 255, 255, 0.12)'}}>
                                    <BillingInfoForm infos={infos} setInfos={setInfos} keepInfo={keepInfo} setKeepInfo={setKeepInfo}/>
                                </AccordionDetails>
                            </Accordion>
                            <Divider orientation="horizontal" variant="middle" flexItem/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={0} md={2}/>
                <Grid item xs={12} md={4}>
                    <Accordion onChange={() => {}} disabled expanded={true} sx={{
                        backgroundColor: "transparent",
                        backgroundImage: "none",
                        boxShadow: 0,
                        height: '100%'
                    }}>
                        <AccordionSummary
                          expandIcon={<></>}
                          aria-controls="panel3-content"
                          id="panel3-header"
                        >
                            <Stack direction="row" alignItems="center" gap={1} sx={{marginTop: 0, marginBottom: 0}}>
                                <EuroIcon/>
                                <Typography variant="h6">Purchase Summary</Typography>
                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails sx={{marginLeft: 2, marginRight: 2, borderTop: 1, borderColor: 'rgba(255, 255, 255, 0.12)'}}>
                            <Grid container>
                                <Grid item xs={12} sx={{textAlign: 'left'}}>
                                    <Stack direction="row" alignItems="center" gap={1}>
                                        <Typography variant="h6">Tokens</Typography>
                                        <Box sx={{flexGrow: 1}}/>
                                        <Typography variant="body1">{tokenAmount}</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sx={{textAlign: 'left'}}>
                                    <Stack direction="row" alignItems="center" gap={1}>
                                        <Typography variant="subtitle1">Price per token (Plan: {capitalize(user ? user.plan.toLowerCase() : "")})</Typography>
                                        <Box sx={{flexGrow: 1}}/>
                                        <Typography variant="body1">{price + " €"}</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sx={{textAlign: 'left'}}>
                                    <Divider orientation="horizontal" variant="middle" flexItem sx={{marginTop: 1, marginBottom: 1}}/>
                                    <Stack direction="row" alignItems="center" gap={1}>
                                        <Typography variant="h6">Sub-total</Typography>
                                        <Box sx={{flexGrow: 1}}/>
                                        <Typography variant="body1">{Math.ceil(amount) / 100 + " €"}</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sx={{textAlign: 'left'}}>
                                    <Stack direction="row" alignItems="center" gap={1}>
                                        <Typography variant="subtitle1">VAT (20%)</Typography>
                                        <Box sx={{flexGrow: 1}}/>
                                        <Typography variant="body1">{Math.ceil(amount * 0.2) / 100 + " €"}</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sx={{textAlign: 'left'}}>
                                    <Divider orientation="horizontal" variant="middle" flexItem sx={{marginTop: 1, marginBottom: 1}}/>
                                    <Stack direction="row" alignItems="center" gap={1}>
                                        <Typography variant="h6">Total price</Typography>
                                        <Box sx={{flexGrow: 1}}/>
                                        <Typography variant="body1">{Math.ceil(amount * 1.2) / 100 + " €"}</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={{m: 1}} justifyContent="center" alignItems="center" textAlign="center">
                                        <form method="POST" action={PAYMENT_URL} ref={formTag}>
                                            <input type="hidden" name="vads_action_mode" value="INTERACTIVE" />
                                            <input type="hidden" name="vads_amount" ref={amountTag}/>
                                            <input type="hidden" name="vads_ctx_mode" value="PRODUCTION" />
                                            <input type="hidden" name="vads_currency" value="978" />
                                            <input type="hidden" name="vads_cust_address" ref={addressTag} />
                                            <input type="hidden" name="vads_cust_city" ref={cityTag} />
                                            <input type="hidden" name="vads_cust_country" ref={countryTag} />
                                            <input type="hidden" name="vads_cust_email" ref={emailTag} />
                                            <input type="hidden" name="vads_cust_first_name" ref={firstNameTag} />
                                            <input type="hidden" name="vads_cust_last_name" ref={lastNameTag} />
                                            <input type="hidden" name="vads_page_action" value="PAYMENT" />
                                            <input type="hidden" name="vads_payment_config" value="SINGLE" />
                                            <input type="hidden" name="vads_site_id" ref={siteIdTag} />
                                            <input type="hidden" name="vads_trans_date" ref={dateTag}  />
                                            <input type="hidden" name="vads_trans_id" ref={transIdTag}  />
                                            <input type="hidden" name="vads_version" value="V2" />
                                            <input type="hidden" name="signature" ref={signatureTag} />
                                        </form>
                                        <Button disabled={infos.firstName === "" || infos.lastName === "" || infos.country === "" || infos.city === "" || infos.address === "" || !checked || !rightChecked} variant="contained" onClick={registerPayment}>
                                            Pay
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12}>
                    <Box component={"div"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        <Typography sx={{textAlign: "center"}}>I have read and I accept the <Link href={"https://dynalips.com/terms-policies-cgu-cgv"} color={"#931a95"}>Terms & Policies</Link>.</Typography>
                        <Checkbox color={"secondary"} checked={checked} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setChecked(event.target.checked)}} inputProps={{ 'aria-label': 'controlled' }}/>
                    </Box>
                    <Box component={"div"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        <Typography sx={{textAlign: "center"}}>I expressly waive my legal right of withdrawal.</Typography>
                        <Checkbox color={"secondary"} checked={rightChecked} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setRightChecked(event.target.checked)}} inputProps={{ 'aria-label': 'controlled' }}/>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}