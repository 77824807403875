import {ChangeEvent, FC, useContext, useEffect, useState} from "react";
import {Box, Button, Grid, Paper, Switch, Typography} from "@mui/material";
import {UserContext} from "../../../../context/user-context";
import {getErrorMessage, notifyUser} from "../../../../utils/notificationCenter";
import {useSnackbar} from "notistack";
import axios from 'axios';
import {PreferredLanguageSelect} from "./components/PreferredLanguageSelect";
import {PreferredAssetSelect} from "./components/PreferredAssetSelect";

const CENTRAL_API_URL = process.env.REACT_APP_CENTRAL_API_URL!
const SETTINGS_ALL_ROUTE = process.env.REACT_APP_SETTINGS_ALL_ROUTE!
const SETTINGS_SAVE_ROUTE = process.env.REACT_APP_SETTINGS_SAVE_ROUTE!

export type PreferredSettings = {
    "deleteOldest": boolean,
    "preferredLanguage": string,
    "preferredAsset": string,
}

export const SettingsBoard: FC = () => {
    const {user} = useContext(UserContext);
    const [edit, setEdit] = useState(false);

    const [deleteOldest, setDeleteOldest] = useState(false);
    const [preferredLanguage, setPreferredLanguage] = useState<string>("");
    const [preferredAsset, setPreferredAsset] = useState<string>("");
    const [preferredSettings, setPreferredSettings] = useState<PreferredSettings>({
        deleteOldest: deleteOldest,
        preferredLanguage: preferredLanguage,
        preferredAsset: preferredAsset,
    })

    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    useEffect(() => {
        if (user) {
            axios.post(CENTRAL_API_URL + SETTINGS_ALL_ROUTE, {
                "jwt": user.jwt,
            })
                .then(res => {
                    setPreferredSettings(res.data);
                    setDeleteOldest(res.data.deleteOldest);
                    setPreferredLanguage(res.data.preferredLanguage);
                    setPreferredAsset(res.data.preferredAsset);
                })
                .catch(error => console.log("Error on fetching your settings from database : ", getErrorMessage(error)))
        }
    }, [user])

    const cancelEdit = () => {
        setEdit(false);
        setDeleteOldest(preferredSettings.deleteOldest);
        setPreferredLanguage(preferredSettings.preferredLanguage);
        setPreferredAsset(preferredSettings.preferredAsset);
    };

    const requestHandler = () => {
        if (user) {
            axios.post(CENTRAL_API_URL + SETTINGS_SAVE_ROUTE, {
                "jwt": user.jwt,
                "deleteOldest": deleteOldest,
                "preferredLanguage": preferredLanguage,
                "preferredAsset": preferredAsset,
            })
                .then(() => {
                    notifyUser("Your settings were saved.", false, enqueueSnackbar, closeSnackbar)
                    setPreferredSettings({
                        deleteOldest: deleteOldest,
                        preferredAsset: preferredAsset,
                        preferredLanguage: preferredLanguage
                    });
                    setEdit(false);
                })
                .catch(error => notifyUser("An error occurred while saving your settings : " + getErrorMessage(error), true, enqueueSnackbar, closeSnackbar));
        }
    }

    return (
        <Box sx={{
            margin: 2,
            flexGrow: 1,
            border: 1,
            borderRadius: "4px",
            borderColor: 'secondary.main',
            padding: "16px"
        }}>
            <Grid container spacing={2} sx={{textAlign: 'center'}}>
                <Grid item xs={12}>
                    <Paper variant="outlined">
                        <Typography variant="h5">Animation settings</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <PreferredLanguageSelect preferredLanguage={preferredLanguage} setPreferredLanguage={setPreferredLanguage} disabled={!edit}/>
                </Grid>
                <Grid item xs={6}>
                    <PreferredAssetSelect preferredAsset={preferredAsset} setPreferredAsset={setPreferredAsset} disabled={!edit}/>
                </Grid>
                <Grid item xs={6}>
                    <Paper variant="outlined" sx={{backgroundColor: "background.default", display: "flex", justifyContent: "space-around", paddingRight: 2}}>
                        <Typography sx={{flexGrow: 1}} alignSelf="center">Delete oldest files when storage limit is reached : </Typography>
                        <Switch checked={deleteOldest} onChange={(event: ChangeEvent<HTMLInputElement>) => setDeleteOldest(event.target.checked)} defaultChecked color="secondary" disabled={!edit}/>
                    </Paper>
                </Grid>
                <Grid item xs={12} sx={{margin: 2}}>
                    {edit ?
                      <>
                        <Button onClick={requestHandler} sx={{fontSize: "1em"}} variant="contained">
                          <Typography sx={{textAlign: "center"}}>Validate</Typography>
                        </Button>
                        <Button onClick={cancelEdit} sx={{marginLeft: "4px", fontSize: "1em"}} variant="contained">
                          <Typography sx={{textAlign: "center"}}>Cancel</Typography>
                        </Button>
                      </> :
                      <Button onClick={() => setEdit(true)} sx={{fontSize: "1em"}} variant="contained">
                          <Typography sx={{textAlign: "center"}}>Edit</Typography>
                      </Button>
                    }
                </Grid>
            </Grid>
        </Box>
    )
}