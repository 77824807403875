import {FC, MouseEvent, useCallback, useContext, useRef, useState} from "react";
import DownloadIcon from "@mui/icons-material/Download";
import {IconButton} from "@mui/material";
import {getErrorMessage, notifyUser} from "../../../../../utils/notificationCenter";
import {UserContext} from "../../../../../context/user-context";
import {useSnackbar} from "notistack";
import axios from 'axios';

export interface HistoryFileDownloadButtonProps {
    dataPath: string,
    filename: string,
}

const CENTRAL_API_URL = process.env.REACT_APP_CENTRAL_API_URL!
const HISTORY_DOWNLOAD_ROUTE = process.env.REACT_APP_HISTORY_DOWNLOAD_ROUTE!

export const HistoryFileDownloadButton: FC<HistoryFileDownloadButtonProps> = ({dataPath, filename}) => {
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const [disable, setDisabled] = useState<boolean>(false);
    const requestSent = useRef<boolean>(false);
    const {user} = useContext(UserContext);


    const downloadHandler = useCallback((event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        if (!requestSent.current && user) {
            setDisabled(true);
            const fullpath = dataPath + filename;
            notifyUser("Downloading file from server...", false, enqueueSnackbar, closeSnackbar)
            axios.post(CENTRAL_API_URL + HISTORY_DOWNLOAD_ROUTE, {
                "jwt": user.jwt,
                "filepath": fullpath
            }, {
                responseType: 'blob'
            })
                .then(data => {
                    const url = window.URL.createObjectURL(data.data);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = filename;
                    a.click();
                    requestSent.current = false;
                    setDisabled(false);
                })
                .catch(error => {
                    notifyUser("An error occurred while downloading file : " + getErrorMessage(error), true, enqueueSnackbar, closeSnackbar)
                    requestSent.current = false;
                    setDisabled(false);
                });
            requestSent.current = true;
        }
    }, [closeSnackbar, enqueueSnackbar, dataPath, filename, user]);

    return <IconButton disabled={disable} onClick={downloadHandler} edge="end" aria-label="download-text" sx={{marginRight: "0px"}}>
        <DownloadIcon/>
    </IconButton>
}