import React, {FC, useContext} from 'react';
import {useEffect, useState} from "react";
import {searchDataFromLabel, SelectWithLoader} from "../../../../../../../utils/components/SelectWithLoader";
import {AssetType, EngineType, loadEngineData} from "../../../../../../../utils/sessionDataLoader";
import {PlaygroundContext} from "../../../../../../../context/playground-context";


interface EngineSelectProps {
    requestData: FormData
    currentAsset: AssetType | undefined
}

export const EngineSelect: FC<EngineSelectProps> = ({requestData, currentAsset}) => {
    const [currentEngine, setCurrentEngine] = useState<EngineType>();
    const [engines, setEngines] = useState<EngineType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [enginesVisibility, setEnginesVisiblity] = useState<boolean[]>([]);
    const {playgroundEnabled} = useContext(PlaygroundContext);


    useEffect(() => {
        setEnginesVisiblity(engines.map((value, _) => {
            return !!currentAsset && currentAsset.engines.includes(value.internalLabel);
        }))
        if (currentAsset && engines.length > 0) {
            setCurrentEngine(searchDataFromLabel(currentAsset.engines[0], engines));
        }
    }, [engines, currentAsset, requestData]);

    useEffect(() => {
        if (currentEngine) {
            requestData.set("engine", currentEngine.internalLabel);
        }
    }, [currentEngine, requestData]);

    return (
      <SelectWithLoader id={"engine"} disabled={playgroundEnabled} dataLoaderFunction={loadEngineData}
                        data={engines} setData={setEngines} currentData={currentEngine} setCurrentData={setCurrentEngine}
                        loading={loading} setLoading={setLoading} dataVisibility={enginesVisibility} replaceCurrentOnLoad/>
    )
}