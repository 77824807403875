import React, {useContext} from 'react';
import {Title} from "../../utils/components/Title";
import {Box, Divider, Drawer, List, ListItem, ListItemButton} from "@mui/material";
import {Link, Route, Routes} from "react-router-dom";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import Typography from "@mui/material/Typography";
import {UserContext} from "../../context/user-context";
import SettingsIcon from '@mui/icons-material/Settings';
import {SettingsBoard} from "./boards/settings_board/SettingsBoard";
import InfoIcon from '@mui/icons-material/Info';
import ReceiptIcon from '@mui/icons-material/Receipt';
import {PaymentBoard} from "./boards/payment_board/PaymentBoard";
import {InfoBoard} from "./boards/info_board/InfoBoard";
import {HistoryBoard} from "./boards/history_board/HistoryBoard";
import {NotFoundBoard} from "../not_found_board/NotFoundBoard";
import {EuroIcon} from "../../utils/components/EuroIcon";
import {BillsBoard} from "./boards/bills_board/BillsBoard";


export const AccountBoard: React.FC = () => {
    const {user} = useContext(UserContext);

    let inner;
    if (user) {
        inner = (
            <Box sx={{ display: 'flex', marginBottom: '64px', marginTop: '64px' }}>
                <Drawer variant={"persistent"} open={true} anchor={"left"} sx={{
                    width: 232,
                }} PaperProps={{
                    sx: {
                        width: "inherit",
                        paddingTop: "64px"
                    }
                }}>
                    <List>
                        <ListItem key={"account-infos"} disablePadding>
                            <Link to={"/account"} style={{width: "inherit", color: 'inherit', textDecoration: 'inherit'}}>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <InfoIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"Accounts infos"} />
                                </ListItemButton>
                            </Link>
                        </ListItem>
                        <Divider />
                        <ListItem key={"view-history"} disablePadding>
                            <Link to={"/account/history"} style={{width: "inherit", color: 'inherit', textDecoration: 'inherit'}}>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <ManageSearchIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"View history"} />
                                </ListItemButton>
                            </Link>
                        </ListItem>
                        <ListItem key={"view-bills"} disablePadding>
                            <Link to={"/account/bills"} style={{width: "inherit", color: 'inherit', textDecoration: 'inherit'}}>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <ReceiptIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"View bills"} />
                                </ListItemButton>
                            </Link>
                        </ListItem>
                        <ListItem key={"payment"} disablePadding>
                            <Link to={"/account/payment"} style={{width: "inherit", color: 'inherit', textDecoration: 'inherit'}}>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <EuroIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"Purchase tokens"} />
                                </ListItemButton>
                            </Link>
                        </ListItem>
                        <ListItem key={"settings"} disablePadding>
                            <Link to={"/account/settings"} style={{width: "inherit", color: 'inherit', textDecoration: 'inherit'}}>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <SettingsIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"Settings & Preferences"} />
                                </ListItemButton>
                            </Link>
                        </ListItem>
                    </List>
                </Drawer>

                <Routes>
                    <Route path="/" element={<InfoBoard/>} />
                    <Route path="settings" element={<SettingsBoard/>} />
                    <Route path="payment" element={<PaymentBoard/>}/>
                    <Route path="history" element={<HistoryBoard/>} />
                    <Route path="bills" element={<BillsBoard/>} />
                    <Route path="*" element={<NotFoundBoard/>} />
                </Routes>
            </Box>
        )
    } else {
        inner = <Typography>You must be connected to see this page</Typography>
    }

    return (
        <>
            <Title text={"Dynalips - Account"}/>
            {inner}
        </>
    )
}
