import {TextField} from "@mui/material";
import React, {ChangeEvent, FC, useContext, useEffect} from "react";
import {SendContext} from "../../../../../../../../../context/send-context";
import {PlaygroundContext} from "../../../../../../../../../context/playground-context";

interface TextBoxInputProps {
    text: string,
    setText: (newText: string) => void
}

export const TextBoxInput: FC<TextBoxInputProps> = ({text, setText}) => {
    const {setTextProvided, setTextVerified} = useContext(SendContext);
    const {playgroundEnabled} = useContext(PlaygroundContext);

    const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
        setText(event.target.value);
    }

    useEffect(() => {
        setTextProvided(text !== "");
        setTextVerified(false);
    }, [setTextProvided, setTextVerified, text]);

    return <TextField disabled={playgroundEnabled} sx={{width: "100%"}} InputProps={{
        style: {
            borderRadius: "0px 0px 8px 8px",
        }
    }} value={text} placeholder="Upload a .txt file or write here" onChange={handleTextChange} multiline/>
}