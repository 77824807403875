import {FC} from "react";
import {IconButton, Paper, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import {NotificationData} from "../NotifsPanel";

export interface NotificationProps {
    setNotifs: (callback: ((prevData: NotificationData[]) => NotificationData[])) => void
    self: NotificationData;
}

export const Notification: FC<NotificationProps> = ({setNotifs, self}) => {
    const handleClose = () => {
        setNotifs((prevNotifs: NotificationData[]) => {
            const newNotifs = [...prevNotifs];
            const idx = newNotifs.indexOf(self);
            newNotifs.splice(idx, 1);
            return newNotifs
        });
        sessionStorage.setItem(self._id, "used");
    };

    return (
        <Paper sx={{marginTop: 0.5, marginBottom: 0.5, display: "flex", alignItems: "center", backgroundColor: self.priority}}>
            <Typography sx={{marginLeft: 1}}>{self.text}</Typography>
            <Box flexGrow={1}/>
            <IconButton onClick={handleClose}>
                <CloseIcon/>
            </IconButton>
        </Paper>
    );

}