import React from 'react';
import {Title} from "../../utils/components/Title";
import {Box} from "@mui/material";
import {WebTextTutorialPanel} from "./components/WebTextTutorialPanel";
import {UnrealVideoTutorialPanel} from "./components/UnrealVideoTutorialPanel";
import {APITextDocumentationPanel} from "./components/documentation_panel/APITextDocumentationPanel";

export const HelpBoard: React.FC = () => {
    return (
        <Box sx={{margin: 2, marginBottom: '80px', marginTop: '80px'}}>
            <Title text={"Dynalips - Help"}/>
            <WebTextTutorialPanel/>
            <APITextDocumentationPanel/>
            <UnrealVideoTutorialPanel/>
        </Box>
    )
}
