import {Button, Divider, List, ListItem, ListItemText} from "@mui/material";
import React, {FC} from "react";
import Tooltip from "@mui/material/Tooltip";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";

export const TextTips: FC = () => {
    return (
        <>
            <Tooltip  placement={"right"} arrow title={
                <List>
                    <ListItem>
                        <ListItemText primary={"If you have numbers/dates in your text:"} secondary={"Please write them in letters."}></ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={"If you have acronyms in your text:"} secondary={"Acronyms are detected as uppercase word, and will be animated letter by letter, if they have to be pronounced as words, e.g NASA, write them in lowercase."}></ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={"If some words aren't correctly recognized:"} secondary={"Try to split them, e.g 'lipsync' becomes 'lip sync'."}></ListItemText>
                    </ListItem>
                </List>
            }>
                <Button sx={{padding: 0, paddingLeft: 1, paddingRight: 1, margin: 0, marginRight: 1}} variant="contained" startIcon={<TipsAndUpdatesIcon/>}>
                    Tips
                </Button>
            </Tooltip>
            <Divider orientation={"vertical"} flexItem  sx={{marginRight: 1}}/>
        </>
    )
}