import React, {FC, PropsWithChildren} from "react";
import {ListItem, ListItemText} from "@mui/material";

interface RouteDocumentationSectionProps extends PropsWithChildren {
  sectionName: string;
}

const RouteDocumentationSection: FC<RouteDocumentationSectionProps> = ({sectionName, children}) => {
  return (
    <>
      <ListItem sx={{paddingTop: 0, paddingBottom: 0}}>
        <ListItemText primary={sectionName}/>
      </ListItem>
      {children}
    </>
  )
};

export const RouteDocumentationParameters: FC<PropsWithChildren> = ({children}) => {
  return (
    <RouteDocumentationSection sectionName={"Parameters:"}>
      {children}
    </RouteDocumentationSection>
  )
};

export const RouteDocumentationReturns: FC<PropsWithChildren> = ({children}) => {
  return (
    <RouteDocumentationSection sectionName={"Returns:"}>
      {children}
    </RouteDocumentationSection>
  )
};
