import React, {FC, useCallback, useContext, useEffect, useState} from "react";
import {FileInput} from "../../FileInput";
import AudioFileRoundedIcon from '@mui/icons-material/AudioFileRounded';
import {PlaygroundContext} from "../../../../../../../../../context/playground-context";

interface AudioInputProps {
    requestData: FormData;
    lengthChangeCallback: (length: number | undefined) => void;
    file: File | undefined;
    setFile: (newFile : File | undefined) => void;
}


export const AudioFileInput: FC<AudioInputProps> = ({requestData, lengthChangeCallback, file, setFile}) => {
    const [length, setLength] = useState<number | undefined>(undefined);
    const {playgroundEnabled, playgroundFile} = useContext(PlaygroundContext);

    const fileChangeCallback = useCallback((file: File | undefined) => {
        if (file === undefined) {
            setLength(undefined);
        } else {
            requestData.set("audio", file);
            const reader = new FileReader();
            reader.onload = function() {
                if (typeof reader.result === "string") {
                    let audioData: string
                    if (reader.result.startsWith("data:application/octet-stream")) {
                        audioData = reader.result.replace("data:application/octet-stream", "data:audio/wav");
                    } else {
                        audioData = reader.result;
                    }
                    // @ts-ignore
                    const media = new Audio(audioData);
                    media.onloadedmetadata = function(){
                        setLength(Math.max(1, media.duration));
                    };
                }
            };
            reader.readAsDataURL(file);
        }
    }, [requestData]);

    useEffect(() => {
        if (playgroundFile !== undefined && playgroundEnabled) {
            const pFile = new File([playgroundFile.audio], playgroundFile.display + ".wav", {
                type: "audio/x-wav"
            });
            setFile(pFile);
            fileChangeCallback(pFile);
        }
    }, [fileChangeCallback, setFile, playgroundFile, playgroundEnabled]);

    useEffect(() => { lengthChangeCallback(length) }, [lengthChangeCallback, length]);

    return <FileInput icon={<AudioFileRoundedIcon/>} fileChangeCallback={fileChangeCallback} accept=".wav" file={file} setFile={setFile}/>
}