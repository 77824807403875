import * as React from 'react';
import Box from '@mui/material/Box';
import {FC, useEffect, useState} from "react";
import {getErrorMessage, notifyUser} from "../../../../utils/notificationCenter";
import {useSnackbar} from "notistack";
import {Notification} from "./components/Notification";
import axios from 'axios';

const CENTRAL_API_URL = process.env.REACT_APP_CENTRAL_API_URL!
const NOTIFICATION_CURRENT_ROUTE = process.env.REACT_APP_NOTIFICATION_CURRENT_ROUTE!

export type NotificationData = {
    "_id": string,
    "text": string,
    "expire_date": string,
    "priority": string,
}

export const NotifsPanel: FC = () => {
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const [notifs, setNotifs] = useState<NotificationData[]>([]);

    useEffect(() => {
        const data = sessionStorage.getItem("notificationData");
        if (data == null) {
            axios.get(CENTRAL_API_URL + NOTIFICATION_CURRENT_ROUTE)
                .then(res => {
                    setNotifs(res.data.notifs);
                    sessionStorage.setItem("notificationData", JSON.stringify(res.data));
                })
                .catch(error => {
                    notifyUser("An error occurred while fetching notifications : " + getErrorMessage(error), true, enqueueSnackbar, closeSnackbar)
                });
        } else {
            setNotifs(JSON.parse(data).notifs);
        }
    }, [closeSnackbar, enqueueSnackbar]);

    return (
        notifs ? <Box sx={{margin: 1}}> {
            notifs.map((notif) => {
                if (sessionStorage.getItem(notif._id) == null) {
                    return <Notification key={notif._id} setNotifs={setNotifs} self={notif}/>
                } else {
                    return <></>
                }
            })
        } </Box> : <></>
    );
};