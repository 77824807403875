import React, {FC, useContext, useState} from 'react';
import {useEffect} from "react";
import {PlaygroundContext} from "../../../../../../../context/playground-context";
import {LanguageType, loadLanguageData} from "../../../../../../../utils/sessionDataLoader";
import {
    searchDataFromDisplay,
    searchDataFromLabel,
    SelectWithLoader
} from "../../../../../../../utils/components/SelectWithLoader";
import {Box} from "@mui/material";

interface LanguageSelectProps {
    requestData: FormData,
    preferredLanguage: string,
}

export const LanguageSelect: FC<LanguageSelectProps> = ({requestData, preferredLanguage}) => {
    const {playgroundEnabled, playgroundFile} = useContext(PlaygroundContext);
    const [languages, setLanguages] = useState<LanguageType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [currentLanguage, setCurrentLanguage] = useState<LanguageType | undefined>();

    useEffect(() => {
        if (playgroundFile !== undefined && playgroundEnabled) {
            setCurrentLanguage(searchDataFromDisplay(playgroundFile.language, languages));
        }
    }, [playgroundFile, playgroundEnabled, languages, requestData]);

    useEffect(() => {
        if (currentLanguage) {
            requestData.set("language", currentLanguage.internalLabel);
        }
    }, [currentLanguage, requestData]);

    useEffect(() => {
        if (preferredLanguage && languages.length > 0) {
            setCurrentLanguage(searchDataFromLabel(preferredLanguage, languages));
        }
    }, [preferredLanguage, languages, setCurrentLanguage])


    return (
      <Box sx={{width: "30%", marginBottom: 1}}>
          <SelectWithLoader id={"language"} disabled={playgroundEnabled} dataLoaderFunction={loadLanguageData}
                            data={languages} setData={setLanguages} currentData={currentLanguage} setCurrentData={setCurrentLanguage}
                            loading={loading} setLoading={setLoading} replaceCurrentOnLoad/>
      </Box>
    )
}