import {FC, useState} from "react";
import {
    Container,
    Grid
} from "@mui/material";
import {ControlPanel} from "./components/control_panel/ControlPanel";
import {ResultsPanel} from "./components/results_panel/ResultsPanel";
import {ResultProps} from "./components/results_panel/components/Result";
import {SendContext} from "../../context/send-context";
import {Title} from "../../utils/components/Title";
import {NotifsPanel} from "./components/notifs_panel/NotifsPanel";
import {PlaygroundFile} from "./components/control_panel/components/data_input_panel/components/PlaygroundSelect";
import {PlaygroundContext} from "../../context/playground-context";

export const AnimationBoard: FC = () => {
    const [results, setResults] = useState<ResultProps[]>([]);
    const [spammingProtection, setSpammingProtection] = useState<boolean>(false);
    const [audioProvided, setAudioProvided] = useState<boolean>(false);
    const [textProvided, setTextProvided] = useState<boolean>(false);
    const [textVerified, setTextVerified] = useState<boolean>(false);
    const [playgroundEnabled, setPlaygroundEnabled] = useState<boolean>(false);
    const [playgroundFile, setPlaygroundFile] = useState<PlaygroundFile | undefined>(undefined);

    return <PlaygroundContext.Provider value={{
        playgroundEnabled: playgroundEnabled,
        playgroundFile: playgroundFile,
        setPlaygroundEnabled: setPlaygroundEnabled,
        setPlaygroundFile: setPlaygroundFile
    }}>
        <SendContext.Provider value={{
            spammingProtection: spammingProtection,
            audioProvided: audioProvided,
            textProvided: textProvided,
            textVerified: textVerified,
            setSpammingProtection: setSpammingProtection,
            setAudioProvided: setAudioProvided,
            setTextProvided: setTextProvided,
            setTextVerified: setTextVerified,
        }}>
            <Title text={"Dynalips - Home"}/>
            <Container maxWidth={false} disableGutters sx={{marginBottom: '80px', marginTop: '80px'}}>
                <NotifsPanel />
                <Grid container>
                    <ControlPanel setResults={setResults}/>
                    <ResultsPanel results={results}/>
                </Grid>
            </Container>
        </SendContext.Provider>
    </PlaygroundContext.Provider>

}
