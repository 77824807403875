import React, {ChangeEvent, FC, useCallback, useContext, useEffect, useRef, useState} from "react";
import {CaretPositioner} from "./CaretPositioner";
import {WordType} from "../../../DataInputPanel";
import {PlaygroundContext} from "../../../../../../../../../context/playground-context";

interface DataInputPanelProps {
    validationList: {[word: string]: WordType},
    setValidationList: (newList: {[word: string]: WordType}) => void;
    validText: string[],
    setValidText: (newText: string[]) => void,
    setText: (newText: string) => void,
    requestData: FormData;
}

export const COLORS = [
    "lightgrey",
    "#2196f3",
    "white",
    "#f44336",
    "#ff9800",
]


export const TextValidationInput: FC<DataInputPanelProps> = ({validationList, setValidationList, validText, setValidText, setText, requestData}) => {
    const textArea = useRef<HTMLInputElement>();
    const {playgroundEnabled, playgroundFile} = useContext(PlaygroundContext);
    const [caretState, setCaretState] = useState<{start: number, end: number}>({
        start : -1,
        end : 0
    })

    const getEditorHTML = useCallback(() => {
        let tmp = "";
        validText.forEach(word => {
            if (word in validationList) {
                const idx = validationList[word];
                if (idx === WordType.LEX_WORD) {
                    tmp += "<div style='display: inline; color:" + COLORS[idx] + "'>" + word + "&nbsp;<wbr></div>"
                } else {
                    tmp += "<div style='display: inline; color:" + COLORS[idx] + "; font-weight: bold'>" + word + "&nbsp;<wbr></div>"
                }
            } else {
                tmp += "<div style='display: inline; color:" + COLORS[0] + "'>" + word + "&nbsp;<wbr></div>"
            }
        });
        return tmp;
    }, [validText, validationList])

    const handleValidTextChange = (event: ChangeEvent<HTMLInputElement>) => {
        const textContent = event.currentTarget.textContent;
        if (textContent != null) {
            let savedCaretPosition = CaretPositioner.saveSelection(event.currentTarget);
            setCaretState(savedCaretPosition);
            setValidText(textContent.trim().split(RegExp("\\s")));
            textArea.current = event.currentTarget;
        }
    }

    useEffect(() => {
        if (playgroundFile === undefined && playgroundEnabled) {
            setValidText([]);
            setValidationList({});
        }
    }, [setValidText, setValidationList, playgroundEnabled, playgroundFile]);

    useEffect(() => {
        if (caretState.start !== -1) {
            if (textArea.current) {
                setText("");
                requestData.set("text", validText.join(" "));
                textArea.current.innerHTML = getEditorHTML();
                CaretPositioner.restoreSelection(document.getElementById("validTextEditor"), caretState);
            }
        }
    }, [getEditorHTML, setText, caretState, requestData, validText])


    return (
        <div style={{border: "1px solid rgba(255, 255, 255, 0.25)", width: "100%", padding: "14px 16.5px", borderRadius: "0px 0px 8px 8px", }} dangerouslySetInnerHTML={{__html: getEditorHTML()}} id={"validTextEditor"} contentEditable={playgroundEnabled ? "false" : "true"} suppressContentEditableWarning={true} onInput={handleValidTextChange}/>
    )
}
