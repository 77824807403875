import {Button, IconButton} from "@mui/material";
import {FC, ChangeEvent, useContext, useEffect, useRef, MutableRefObject, useCallback} from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import {UserContext} from "../../../../../../../context/user-context";
import {PlaygroundContext} from "../../../../../../../context/playground-context";

interface Props {
    icon: any;
    fileChangeCallback: (file: File | undefined) => void;
    accept: string;
    file: File | undefined;
    setFile: (newFile : File | undefined) => void;
}

export const FileInput: FC<Props> = ({icon, fileChangeCallback, accept, file, setFile}) => {
    const fileInputRef = useRef() as MutableRefObject<HTMLInputElement>;
    const {user} = useContext(UserContext);
    const {playgroundEnabled, playgroundFile} = useContext(PlaygroundContext);

    const inputHandler = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files === null) {
            return;
        }
        setFile(event.target.files[0]);
    }

    const resetInput = useCallback(() => {
        fileInputRef.current.value = "";
        setFile(undefined);
    }, [setFile])

    useEffect(() => {
        if (!user) {
            resetInput()
        }
    }, [resetInput, user])

    useEffect(() => {
        fileChangeCallback(file);
    }, [fileChangeCallback, file])

    useEffect(() => {
        if (playgroundFile === undefined || !playgroundEnabled) {
            fileInputRef.current.value = "";
            setFile(undefined);
            fileChangeCallback(undefined);
        }
    }, [setFile, fileChangeCallback, playgroundFile, playgroundEnabled]);

    const text = file ? file.name : "Upload"
    const resetButton = file ? <IconButton disabled={playgroundEnabled} onClick={resetInput} edge="end" aria-label="reset" sx={{position: "relative", p: 1, marginRight: 0}}><CancelIcon/></IconButton> : <></>

    return (
        <>
            <Button disabled={playgroundEnabled} sx={{padding: 0, paddingLeft: 1, paddingRight: 1, marginRight: file ? 0 : 1, marginLeft: 1}} variant="contained" component="label" startIcon={icon}>
                {text}
                <input type="file" onChange={inputHandler} ref={fileInputRef} accept={accept} hidden/>
            </Button>
            {resetButton}
        </>
    )
}