import {FC} from 'react';
import {Navigate, useLocation} from "react-router-dom";
import {useCookies} from "react-cookie";

export const HandleConnection: FC = () => {
    const [, setUserCookie] = useCookies(["jwt"])
    const search = useLocation().search
    const token = new URLSearchParams(search).get('jwt');
    try {
        setUserCookie('jwt', token as string, { path: '/' });
    } catch (e) {}
    return <Navigate to='/'/>
}
