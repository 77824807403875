import React, {FC, useEffect, useState} from "react";
import {LanguageType, loadLanguageData} from "../../../../../utils/sessionDataLoader";
import {searchDataFromLabel, SelectWithLoader} from "../../../../../utils/components/SelectWithLoader";

interface PreferredLanguageSelectProps {
  preferredLanguage: string,
  setPreferredLanguage: (newElem: string) => void,
  disabled: boolean
}

export const PreferredLanguageSelect: FC<PreferredLanguageSelectProps> = ({preferredLanguage, setPreferredLanguage, disabled}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [languages, setLanguages] = useState<LanguageType[]>([]);
  const [currentLanguage, setCurrentLanguage] = useState<LanguageType | undefined>();

  useEffect(() => {
    if (!disabled && currentLanguage) {
      setPreferredLanguage(currentLanguage?.internalLabel);
    }
  }, [disabled, currentLanguage, setPreferredLanguage]);

  useEffect(() => {
    if (disabled && preferredLanguage) {
      setCurrentLanguage(searchDataFromLabel(preferredLanguage, languages));
    }
  }, [disabled, languages, preferredLanguage])

  return (
    <SelectWithLoader id={"preferred language"} disabled={disabled} dataLoaderFunction={loadLanguageData}
                      data={languages} setData={setLanguages} currentData={currentLanguage} setCurrentData={setCurrentLanguage}
                      loading={loading} setLoading={setLoading}/>
  )
}