import React, {useContext, useEffect, useState} from 'react';
import {UserContext} from "../../../../context/user-context";
import {getErrorMessage, notifyUser} from "../../../../utils/notificationCenter";
import {useSnackbar} from "notistack";
import {Link, Route, Routes} from "react-router-dom";
import {NotFoundBoard} from "../../../not_found_board/NotFoundBoard";
import {StatsGrid} from "./components/StatsGrid";
import {StatsModelPerfChart} from "./components/StatsModelPerfChart";
import {Box, Button, Checkbox, Divider, Paper, Stack, Typography} from "@mui/material";
import {StatsAnimationLengthChart} from "./components/StatsAnimationLengthChart";
import {StatsModelUsageChart} from "./components/StatsModelUsageChart";
import {StatsCalendarUsageChart} from "./components/StatsCalendarUsageChart";
import {StatsAssetUsageChart} from "./components/StatsAssetUsageChart";
import axios from 'axios';

export type Stat = {
    "model": string,
    "user": string,
    "date": string,
    "executionTime": number,
    "cost": number,
    "asset": string,
    "mode": string,
    "type": string,
}

export const CHARTS_COLORS = [
    'rgba(255, 99, 132, 0.2)',
    'rgba(54, 162, 235, 0.2)',
    'rgba(255, 206, 86, 0.2)',
    'rgba(75, 192, 192, 0.2)',
    'rgba(153, 102, 255, 0.2)',
    'rgba(255, 159, 64, 0.2)',
]

export const CHARTS_BORDER_COLORS = [
    'rgba(255, 99, 132, 1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)',
]

const CENTRAL_API_URL = process.env.REACT_APP_CENTRAL_API_URL!
const STATS_ALL_ROUTE = process.env.REACT_APP_STATS_ALL_ROUTE!

function filterStats(stats: Stat[], onlyUser: boolean, onlyNormal: boolean, onlyRealtime: boolean): Stat[] {
    return stats.filter((sample: Stat, _1: number, _2: Stat[]) => {
        let kept = true;
        if (onlyUser && sample.type !== "USER") {
            kept = false;
        }
        if (onlyNormal && sample.mode !== "NORMAL") {
            kept = false;
        }
        if (onlyRealtime && sample.mode !== "REALTIME") {
            kept = false
        }
        return kept;
    })
}

export const StatisticsBoard: React.FC = () => {
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const {user} = useContext(UserContext);
    const [onlyUser, setOnlyUser] = useState<boolean>(false);
    const [onlyNormal, setOnlyNormal] = useState<boolean>(false);
    const [onlyRealtime, setOnlyRealtime] = useState<boolean>(false);
    const [stats, setStats] = useState<Stat[]>([]);
    const [filteredStats, setFilteredStats] = useState<Stat[]>([]);

    useEffect(() => {
        if (user?.perm === "ADMIN") {
            axios.post(CENTRAL_API_URL + STATS_ALL_ROUTE, {
                "jwt": user.jwt,
            })
            .then(res => {
                setStats(res.data.stats);
                setFilteredStats(res.data.stats);
            })
            .catch(error => notifyUser("An error occurred while fetching statistics : " + getErrorMessage(error), true, enqueueSnackbar, closeSnackbar))
        }
    }, [closeSnackbar, enqueueSnackbar, user]);

    // useEffect(() => {
    //     setStats(getMockData(100, false));
    // }, [user]);


    useEffect(() => {
        setFilteredStats(filterStats(stats, onlyUser, onlyNormal, onlyRealtime));
    }, [stats, onlyUser, onlyNormal, onlyRealtime])


    return (
        <Box sx={{margin: 1, flexGrow: 1}}>
            <Paper component={Stack} direction="row" sx={{marginTop: 1, marginBottom: 1}}>
                <Link to={"./"} style={{width: "inherit", color: 'inherit', textDecoration: 'inherit'}}>
                    <Button variant="contained" sx={{margin: 1}}>Raw statistics</Button>
                </Link>
                <Divider orientation={"vertical"} flexItem />
                <Link to={"./assets_usage"} style={{width: "inherit", color: 'inherit', textDecoration: 'inherit'}}>
                    <Button variant="contained" sx={{margin: 1}}>Assets usage</Button>
                </Link>
                <Divider orientation={"vertical"} flexItem />
                <Link to={"./models_usage"} style={{width: "inherit", color: 'inherit', textDecoration: 'inherit'}}>
                    <Button variant="contained" sx={{margin: 1}}>Models usage</Button>
                </Link>
                <Divider orientation={"vertical"} flexItem />
                <Link to={"./models_performance"} style={{width: "inherit", color: 'inherit', textDecoration: 'inherit'}}>
                    <Button variant="contained" sx={{margin: 1}}>Models performance</Button>
                </Link>
                <Divider orientation={"vertical"} flexItem />
                <Link to={"./animation_length"} style={{width: "inherit", color: 'inherit', textDecoration: 'inherit'}}>
                    <Button variant="contained" sx={{margin: 1}}>Animation length</Button>
                </Link>
                <Divider orientation={"vertical"} flexItem />
                <Link to={"./calendar_usage"} style={{width: "inherit", color: 'inherit', textDecoration: 'inherit'}}>
                    <Button variant="contained" sx={{margin: 1}}>Usage over time</Button>
                </Link>
                <Box sx={{flexGrow: 1}}/>
                <Box sx={{marginRight: 1, display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <Checkbox checked={onlyUser} onChange={() => setOnlyUser(!onlyUser)} inputProps={{ 'aria-label': 'controlled' }}/>
                    <Typography>Filter only User</Typography>
                </Box>
                <Divider orientation={"vertical"} flexItem />
                <Box sx={{marginRight: 1, display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <Checkbox checked={onlyNormal} onChange={() => {
                        setOnlyNormal(!onlyNormal);
                        setOnlyRealtime(false)
                    }} inputProps={{ 'aria-label': 'controlled' }}/>
                    <Typography>Filter only normal request</Typography>
                </Box>
                <Divider orientation={"vertical"} flexItem />
                <Box sx={{marginRight: 1, display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <Checkbox checked={onlyRealtime} onChange={() => {
                        setOnlyRealtime(!onlyRealtime);
                        setOnlyNormal(false);
                    }} inputProps={{ 'aria-label': 'controlled' }}/>
                    <Typography>Filter only realtime request</Typography>
                </Box>
            </Paper>
            <Routes>
                <Route path="/" element={<StatsGrid stats={filteredStats}/>} />
                <Route path="/assets_usage" element={<StatsAssetUsageChart stats={filteredStats}/>} />
                <Route path="/models_usage" element={<StatsModelUsageChart stats={filteredStats}/>} />
                <Route path="/models_performance" element={<StatsModelPerfChart stats={filteredStats}/>} />
                <Route path="/animation_length" element={<StatsAnimationLengthChart stats={filteredStats}/>} />
                <Route path="/calendar_usage" element={<StatsCalendarUsageChart stats={filteredStats}/>} />
                <Route path="*" element={<NotFoundBoard/>} />
            </Routes>
        </Box>
    )
}
