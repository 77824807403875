import React, {FC, PropsWithChildren} from "react";
import {ListItem, ListItemText} from "@mui/material";

interface RouteDocumentationParameterProps {
  name: string;
  type: string;
  description: string;
}

export const RouteDocumentationParameter: FC<RouteDocumentationParameterProps> = ({name, type, description}) => {
  return (
    <ListItem dense sx={{paddingLeft: 4, paddingTop: 0, paddingBottom: 0}}>
      <ListItemText primary={name + ": " + type} secondary={description}/>
    </ListItem>
  )
};
