import React, {FC} from "react";
import {Box, Link, Paper, useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";

export const Footer: FC = () => {
  const theme = useTheme();

  return (
    <Paper sx={{width: '100%', position: 'fixed', bottom: 0, zIndex: theme.zIndex.drawer + 1, display: "flex", flexDirection: "column", alignItems: "center"}} component="footer" square variant="outlined">
      <Box>
        <Typography variant="caption">
          Any problem ? Send us a mail at <Link href={"mailto:support@dynalips.com"} color={"white"} underline="none">support@dynalips.com</Link> - <Link href={"https://dynalips.com/terms-policies-cgu-cgv"} color={"white"} underline="hover">Terms & Policies</Link> - <Link href={"https://dynalips.com/mentions-legales/"} color={"white"} underline="hover">Legal notice</Link> - <Link href={"https://dynalips.com/privacy-policy-politique-de-confidentialite"} color={"white"} underline="hover">Privacy Policy</Link> - © 2024 Dynalips All Rights Reserved
        </Typography>
      </Box>
      <Box sx={{width: '20%', display: "flex", justifyContent: "space-around", marginTop: 1}}>
        <Link href={"https://x.com/dynalips"} underline="none" target={"_blank"} rel={"noopener"}>
          <Box component="img" sx={{height: 16, maxWidth: 16}} alt="X" src={"/x_512_white.png"}/>
        </Link>
        <Link href={"https://linkedin.com/company/dynalips"} underline="none" target={"_blank"} rel={"noopener"}>
          <Box component="img" sx={{height: 16, maxWidth: 16}} alt="Linkedin" src={"/linkedin_512_white.png"}/>
        </Link>
        <Link href={"https://www.youtube.com/@dynalips"} underline="none" target={"_blank"} rel={"noopener"}>
          <Box component="img" sx={{height: 16, maxWidth: 16}} alt="YouTube" src={"/youtube_512_white.png"}/>
        </Link>
        <Link href={"https://discord.gg/MgbBCFj6G6"} underline="none" target={"_blank"} rel={"noopener"}>
          <Box component="img" sx={{height: 16, maxWidth: 16}} alt="Discord" src={"/discord_512_white.png"}/>
        </Link>
      </Box>
    </Paper>
  )
}