import React, {useContext, useEffect, useState} from 'react';
import {Box, CircularProgress, Grid, List, ListItem, Paper, Stack, Typography} from "@mui/material";
import {UserContext} from "../../../../context/user-context";
import {getErrorMessage, notifyUser} from "../../../../utils/notificationCenter";
import {useSnackbar} from "notistack";
import ApiIcon from '@mui/icons-material/Api';
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import axios from 'axios';


const CENTRAL_API_URL = process.env.REACT_APP_CENTRAL_API_URL!
const PING_ALL_ROUTE = process.env.REACT_APP_PING_ALL_ROUTE!

export const ServerStatusBoard: React.FC = () => {
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const [pingData, setPingData] = useState({
        kaldi: false,
        infer: false,
        retarget: false,
        fbx: false
    });
    const [loading, setLoading] = useState(true);
    const {user} = useContext(UserContext);

    useEffect(() => {
        if (user?.perm === "ADMIN") {
            axios.post(CENTRAL_API_URL + PING_ALL_ROUTE, {
                "jwt": user.jwt,
            })
                .then(res => {
                    setPingData(res.data);
                    setLoading(false);
                })
                .catch(error => {
                    setLoading(false);
                    notifyUser("An error occurred while pinging servers : " + getErrorMessage(error), true, enqueueSnackbar, closeSnackbar)
                })
        }
    }, [closeSnackbar, enqueueSnackbar, user]);

    return (
        <Box sx={{width: 1, margin: 2}}>
            <Paper component={Stack} direction="row" sx={{padding: 1}}>
                <Typography variant="h4">Servers status : </Typography>
            </Paper>
            <Box sx={{
                backgroundColor: 'primary.main', border:1, borderRadius: "4px", borderColor:'secondary.main', padding: "8px", marginTop: 1}}>
                <List>
                    <Grid container>
                        <Grid xs={12}>
                            <ListItem>
                                <ListItemIcon>
                                    {loading ? <CircularProgress color={"secondary"}/> : pingData.kaldi ? <ApiIcon color={"success"}/> : <ApiIcon color={"error"}/>}
                                </ListItemIcon>
                                <ListItemText primary={"Kaldi"} />
                            </ListItem>
                        </Grid>
                        <Grid xs={12}>
                            <ListItem>
                                <ListItemIcon>
                                    {loading ? <CircularProgress color={"secondary"}/> : pingData.infer ? <ApiIcon color={"success"}/> : <ApiIcon color={"error"}/>}
                                </ListItemIcon>
                                <ListItemText primary={"Inference"} />
                            </ListItem>
                        </Grid>
                        <Grid xs={12}>
                            <ListItem>
                                <ListItemIcon>
                                    {loading ? <CircularProgress color={"secondary"}/> : pingData.retarget ? <ApiIcon color={"success"}/> : <ApiIcon color={"error"}/>}
                                </ListItemIcon>
                                <ListItemText primary={"Retarget"} />
                            </ListItem>
                        </Grid>
                        <Grid xs={12}>
                            <ListItem>
                                <ListItemIcon>
                                    {loading ? <CircularProgress color={"secondary"}/> : pingData.fbx ? <ApiIcon color={"success"}/> : <ApiIcon color={"error"}/>}
                                </ListItemIcon>
                                <ListItemText primary={"FBX"} />
                            </ListItem>
                        </Grid>
                    </Grid>
                </List>
            </Box>
        </Box>
    )

}
