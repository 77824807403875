import React, {useEffect, useState} from "react";
import {CHARTS_BORDER_COLORS, Stat} from "../StatisticsBoard";
import {Bar} from "react-chartjs-2";
import {
    BarElement,
    CategoryScale,
    Chart,
    Legend,
    LinearScale,
    Title,
    Tooltip
} from "chart.js";
import {Paper, Stack} from "@mui/material";

interface StatsCalendarUsageChartProps {
    stats: Stat[]
}

interface Dataset {
    label: string,
    data: number[],
    backgroundColor: string,
}

interface Datasets {
    labels: string[]
    datasets : Dataset[]
}

Chart.register( CategoryScale, LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend);

const MONTH_NAMES = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
];


function getMonthIndex(date: Date, last12months: string[]) {
    const month = date.getUTCMonth()
    const year = date.getUTCFullYear();
    return last12months.indexOf(MONTH_NAMES[month] + " " + year.toString());
}

function arrayToDataset(stats: Stat[]) {
    const today = new Date();

    const last12Months: string[] = [];
    for (let i = 0; i < 12; i++) {
        last12Months.push(MONTH_NAMES[today.getMonth()] + " " + today.getFullYear());
        today.setMonth(today.getMonth() - 1);
    }
    last12Months.reverse();

    const labels = Array.from(new Set(stats.map(item => item.model.substring(item.model.indexOf("_") + 1, item.model.length))));

    let datasets: Dataset[] = labels.map(language => ({
        label: language,
        data: last12Months.map(() => 0),
        backgroundColor: CHARTS_BORDER_COLORS[labels.indexOf(language) % CHARTS_BORDER_COLORS.length]
    }));

    datasets.push({
        label: "Total",
        data: last12Months.map(() => 0),
        backgroundColor: "black"
    });

    stats.forEach(item => {
        let language = item.model.substring(item.model.indexOf("_") + 1, item.model.length);
        let binIdx = getMonthIndex(new Date(item.date), last12Months);
        if (binIdx !== -1) {
            datasets[labels.indexOf(language)].data[binIdx] += item.cost;
            datasets[datasets.length - 1].data[binIdx] += item.cost;
        }
    });

    return {
        datasets: datasets,
        labels: last12Months
    };
}

export const StatsCalendarUsageChart: React.FC<StatsCalendarUsageChartProps> = ({stats}) => {
    const plugin = {
        id: 'customCanvasBackgroundColor',
        beforeDraw: (chart: any, args: any, options: any) => {
            const {ctx} = chart;
            ctx.save();
            ctx.globalCompositeOperation = 'destination-over';
            ctx.fillStyle = "white";
            ctx.fillRect(0, 0, chart.width, chart.height);
            ctx.restore();
        }
    };

    const options = {
        responsive: true,
        maintainAspectRatio : false,
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Seconds of animation'
                },
                ticks: {
                    stepSize: 60,
                },
            },
            x: {
                title: {
                    display: true,
                    text: 'Time'
                }
            },
        },
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: "Seconds of animation over the last 12 months"
            }
        },
    };

    const [datasets, setDatasets] = useState<Datasets>({
        labels: [],
        datasets: []
    });

    useEffect(() => {setDatasets(arrayToDataset(stats))}, [stats]);

    return (
        <Paper component={Stack} display="flex" justifyContent="center" sx={{backgroundColor: "white", paddingTop: "16px", paddingBottom: "16px"}}>
        <div style={{position: "relative", height: "70vh", width: "80vw"}}>
            <Bar options={options} data={datasets} plugins={[plugin]}/>
        </div>
    </Paper>
)
}