import {Button, Divider, List, ListItem, ListItemText} from "@mui/material";
import React, {FC} from "react";
import Tooltip from "@mui/material/Tooltip";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";

export const AudioTips: FC = () => {
    return (
        <>
            <Tooltip placement={"right"} arrow title={
                <List>
                    <ListItem>
                        <ListItemText primary={"Make sure that your audio is of a native speaker:"} secondary={"Non-native speech (or strong accent) can simply not be recognized and may fail to animate correctly."}></ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={"Make sure that there is no music/voice effect:"} secondary={"Loud background music/voice effect can lead to a failure of the system. If you need it, please try to filter those effect as much as possible."}></ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={"Audio should be at least mono 16kHz:"} secondary={"Above isn't useful (as well as stereo), below can degrade performance of our system."}></ListItemText>
                    </ListItem>
                </List>
            }>
                <Button sx={{padding: 0, paddingLeft: 1, paddingRight: 1, margin: 0, marginRight: 1}} variant="contained" startIcon={<TipsAndUpdatesIcon/>}>
                    Tips
                </Button>
            </Tooltip>
            <Divider orientation={"vertical"} flexItem  sx={{marginRight: 1}}/>
        </>
    )
}