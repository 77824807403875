import {Accordion, AccordionDetails, List, Typography} from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {RouteDocumentation} from "./components/RouteDocumentation";
import {RouteDocumentationParameters, RouteDocumentationReturns} from "./components/RouteDocumentationSection";
import {RouteDocumentationParameter} from "./components/RouteDocumentationParameter";
import {FC} from "react";

const RouteJwtParameter: FC = () => <RouteDocumentationParameter name={"jwt"} type={"string"} description={"A valid authentication token."}/>;
const RouteAssetParameter: FC = () => <RouteDocumentationParameter name={"asset"} type={"string"} description={"The asset id you want to animate (e.g 'metahuman' for MetaHuman models)."}/>;
const RouteEngineParameter: FC = () => <RouteDocumentationParameter name={"engine"} type={"string"} description={"The engine id you want to the animation to be baked for (e.g 'unreal5' for Unreal Engine 5)."}/>;
const RouteLanguageParameter: FC = () => <RouteDocumentationParameter name={"language"} type={"string"} description={"The language id to use. One of ['fr-fr', 'en-us', 'de-de']."}/>;
const RouteTextParameter: FC = () => <RouteDocumentationParameter name={"text"} type={"string"} description={"The audio transcription text."}/>;
const RouteAudioParameter: FC = () => <RouteDocumentationParameter name={"audio"} type={"file - audio/wav"} description={"A .wav file containing the speech."}/>;

export const APITextDocumentationPanel: FC = () => {
  return (
    <Accordion disableGutters={true}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
        API Documentation
      </AccordionSummary>
      <AccordionDetails sx={{backgroundColor: "background.default", padding: "16px"}}>
        <RouteDocumentation routeTitle={"API token"} routeExplanation={"Every API routes uses a parameter 'jwt' to provide authentication."} routeType={"JSON Post"} route={"login/api/login"}>
          <RouteDocumentationParameters>
            <RouteDocumentationParameter name={"email"} type={"string"} description={"Your account email."}/>
            <RouteDocumentationParameter name={"password"} type={"string"} description={"Your account password."}/>
          </RouteDocumentationParameters>
          <RouteDocumentationReturns>
            <RouteDocumentationParameter name={"jwt"} type={"string"} description={"Your authentication token. Valid for 2 hours."}/>
          </RouteDocumentationReturns>
        </RouteDocumentation>
        <RouteDocumentation routeTitle={"Generate .FBX lipsync"} routeExplanation={"Use this route to generate an .fbx file from audio/text input. Output will be saved in your Account animation history."} routeType={"multipart/form-data POST"} route={"api/process/normal"}>
          <RouteDocumentationParameters>
            <RouteJwtParameter/>
            <RouteAssetParameter/>
            <RouteEngineParameter/>
            <RouteLanguageParameter/>
            <RouteTextParameter/>
            <RouteDocumentationParameter name={"textName"} type={"string"} description={"Your text file name."}/>
            <RouteAudioParameter/>
          </RouteDocumentationParameters>
          <RouteDocumentationReturns>
            <RouteDocumentationParameter name={"animation"} type={"string"} description={"The .fbx file content."}/>
            <RouteDocumentationParameter name={"filename"} type={"string"} description={"The .fbx file name."}/>
          </RouteDocumentationReturns>
        </RouteDocumentation>
        <RouteDocumentation routeTitle={"Generate fast lipsync"} routeExplanation={"Use this route to directly generate animation parameters from audio/text input. Way faster route but outputs raw parameters with no history save."} routeType={"multipart/form-data POST"} route={"api/process/realtime"}>
          <RouteDocumentationParameters>
            <RouteJwtParameter/>
            <RouteAssetParameter/>
            <RouteEngineParameter/>
            <RouteLanguageParameter/>
            <RouteTextParameter/>
            <RouteAudioParameter/>
          </RouteDocumentationParameters>
          <RouteDocumentationReturns>
            <RouteDocumentationParameter name={"asset"} type={"string"} description={"The used asset name."}/>
            <RouteDocumentationParameter name={"data"} type={"number[][]"} description={"An array of animation parameters. First dimension is the animation parameters space (see Skeleton route), second is the time dimension."}/>
          </RouteDocumentationReturns>
        </RouteDocumentation>
        <RouteDocumentation routeTitle={"Skeleton information"} routeExplanation={"The fast lipsync generation route provide an array of animation parameters in a particular order. To optimise network payload and performance those order information are only provided through this route."} routeType={"JSON POST"} route={"api/process/subject"}>
          <RouteDocumentationParameters>
            <RouteJwtParameter/>
            <RouteAssetParameter/>
            <RouteEngineParameter/>
          </RouteDocumentationParameters>
          <RouteDocumentationReturns>
            <RouteDocumentationParameter name={"asset"} type={"string"} description={"The used asset name."}/>
            <RouteDocumentationParameter name={"channels"} type={"string[]"} description={"A list of animation parameters names. The order of this list is the same as the order of animation parameters provided through the fast lipsync generation route."}/>
          </RouteDocumentationReturns>
        </RouteDocumentation>
      </AccordionDetails>
    </Accordion>
  )
}
