import React, {useEffect, useState} from "react";
import {CHARTS_BORDER_COLORS, CHARTS_COLORS, Stat} from "../StatisticsBoard";
import {ArcElement, Chart, Legend, Title, Tooltip} from "chart.js";
import {Box, Paper, Stack} from "@mui/material";
import {Pie} from "react-chartjs-2";

interface StatsAssetUsageChartProps {
    stats: Stat[]
}

interface Dataset {
    label: string,
    data: number[],
    borderColor: string[],
    backgroundColor: string[],
}

interface Datasets {
    labels: string[],
    datasets : Dataset[]
}

Chart.register(ArcElement, Title, Tooltip, Legend);

function arrayToDataset(stats: Stat[]) {
    const labels = Array.from(new Set(stats.map(item => item.asset)));
    const nb_occ_datasets: Dataset = {
        label: "Number of animation",
        data: labels.map(() => 0),
        borderColor: labels.map((model_name) => CHARTS_BORDER_COLORS[labels.indexOf(model_name) % CHARTS_BORDER_COLORS.length]),
        backgroundColor: labels.map((model_name) => CHARTS_COLORS[labels.indexOf(model_name) % CHARTS_COLORS.length])
    };
    const nb_time_datasets: Dataset = {
        label: "Number of seconds",
        data: labels.map(() => 0),
        borderColor: labels.map((model_name) => CHARTS_BORDER_COLORS[labels.indexOf(model_name) % CHARTS_BORDER_COLORS.length]),
        backgroundColor: labels.map((model_name) => CHARTS_COLORS[labels.indexOf(model_name) % CHARTS_COLORS.length])
    };
    stats.forEach(item => {
        nb_occ_datasets.data[labels.indexOf(item.asset)] += 1;
        nb_time_datasets.data[labels.indexOf(item.asset)] += item.cost;
    })
    return [{
        labels: labels,
        datasets: [nb_occ_datasets]
    }, {
        labels: labels,
        datasets: [nb_time_datasets]
    }];
}

export const StatsAssetUsageChart: React.FC<StatsAssetUsageChartProps> = ({stats}) => {
    const plugin = {
        id: 'customCanvasBackgroundColor',
        beforeDraw: (chart: any, args: any, options: any) => {
            const {ctx} = chart;
            ctx.save();
            ctx.globalCompositeOperation = 'destination-over';
            ctx.fillStyle = "white";
            ctx.fillRect(0, 0, chart.width, chart.height);
            ctx.restore();
        }
    };

    const optionsOcc = {
        responsive: true,
        maintainAspectRatio : false,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: "Asset usage by users in occurrences"
            }
        },
    };

    const optionsSec = {
        responsive: true,
        maintainAspectRatio : false,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: "Asset usage by users in seconds"
            }
        },
    };

    const [nbOccDatasets, setNbOccDatasets] = useState<Datasets>({
        labels: [],
        datasets: []
    });

    const [nbSecDatasets, setNbSecDatasets] = useState<Datasets>({
        labels: [],
        datasets: []
    });

    useEffect(() => {
        const datasets = arrayToDataset(stats);
        setNbOccDatasets(datasets[0]);
        setNbSecDatasets(datasets[1]);
    }, [stats]);

    return (
        <Paper component={Stack} display="flex" justifyContent="center" sx={{backgroundColor: "white", paddingTop: "16px", paddingBottom: "16px"}}>
            <Box sx={{display: "flex"}}>
                <Box sx={{borderRight: 1, borderColor: 'primary.main', position: "relative", height: "70vh", width: "40vw"}}>
                    <Pie options={optionsOcc} data={nbOccDatasets} plugins={[plugin]}/>
                </Box>
                <Box sx={{position: "relative", height: "70vh", width: "40vw"}}>
                    <Pie options={optionsSec} data={nbSecDatasets} plugins={[plugin]}/>
                </Box>
            </Box>
        </Paper>
    )
}