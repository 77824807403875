import {FC, useContext, useEffect, useState} from "react";
import {Button} from "@mui/material";
import Box from "@mui/material/Box";
import {ResultProps} from "../../results_panel/components/Result";
import {UserContext} from "../../../../../context/user-context";
import {SendContext} from "../../../../../context/send-context";
import {notifyUser} from "../../../../../utils/notificationCenter"
import {useSnackbar} from "notistack";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';


interface Props {
    requestData: FormData
    setResults: (newElem: ResultProps[] | ((prev: ResultProps[]) => ResultProps[])) => void
}


export const SendButton: FC<Props> = ({setResults, requestData}) => {
    const {user, validUser} = useContext(UserContext);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const {spammingProtection, audioProvided, textProvided, textVerified, setSpammingProtection} = useContext(SendContext);
    const [isErrored, setErrored] = useState<boolean>(true);

    useEffect(() => {
        setErrored(spammingProtection || !audioProvided ||!textProvided || !textVerified || user === undefined)
    }, [spammingProtection, audioProvided, textProvided, textVerified, user])

    const buttonHandler = async () => {
        //firstly check if the user is connected
        validUser(user)
        if(!user) {
            notifyUser("You need to be connected to send request", true, enqueueSnackbar, closeSnackbar)
            return
        }
        if (!requestData.has("textName")) {
            const audio = requestData.get("audio");
            // Shouldn't be null anyway
            if (audio) {
                requestData.set("textName",  (audio as File).name.replace(".wav", ".txt"))
            }
        }
        setSpammingProtection(true)
        requestData.set("jwt", user.jwt)
        requestData.set("model", "m1")

        // Clone the form data to allow further modifications
        const data = new FormData();
        for (const [key, value] of requestData.entries()) {
            data.append(key, value)
        }

        const result: ResultProps = {
            requestData: data,
        }
        // To handle the potential change of name when not using text file upload
        requestData.delete("textName")

        setResults((prev) => [...prev, result])
    }
    return (
        <Box sx={{m: 1, alignSelf: 'center'}}>
            <Button disabled={isErrored} variant="contained" onClick={buttonHandler} startIcon={<AutoFixHighIcon/>}>
                Animate
            </Button>
        </Box>
    )
}
