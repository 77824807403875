import React from 'react';
import {Box, Typography} from "@mui/material";

export const NotFoundBoard: React.FC = () => {
    return (
        <Box sx={{margin: 2, marginBottom: '80px', marginTop: '80px'}}>
            <Typography>This page does not exist.</Typography>
        </Box>
    )
}
