import React, {ChangeEvent, FC, useContext, useEffect, useRef, useState} from "react";
import {Paper, TextField, Typography, Grid, Button} from "@mui/material";
import {UserContext} from "../../../../../context/user-context";
import axios from 'axios';
import {getErrorMessage, notifyUser} from "../../../../../utils/notificationCenter";
import {useSnackbar} from "notistack";
import {CountrySelect} from "./CountrySelect";
import {PersonalInfos} from "../../../../../user/infosProvider";

const ID_PROVIDER_API_URL = process.env.REACT_APP_ID_PROVIDER_API_URL!
const ACCOUNTS_UPDATE_PERSONAL_INFOS_ROUTE = process.env.REACT_APP_ACCOUNTS_UPDATE_PERSONAL_INFOS_ROUTE!
const ACCOUNTS_PERSONAL_INFO_ROUTE = process.env.REACT_APP_ACCOUNTS_PERSONAL_INFOS_ROUTE!

export const InfoForm: FC = () => {
  const {user} = useContext(UserContext);
  const [edit, setEdit] = useState(false);
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const requestSent = useRef<boolean>(false);
  const [infos, setInfos] = useState<PersonalInfos>({
    firstName: "",
    lastName: "",
    country: "",
  });
  const [visibleInfos, setVisibleInfos] = useState<PersonalInfos>(infos);

  useEffect(() => {
    if (!requestSent.current && user) {
      axios.post(ID_PROVIDER_API_URL + ACCOUNTS_PERSONAL_INFO_ROUTE, {
        "jwt": user.jwt
      })
        .then(response => {
          setInfos({
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            country: response.data.country,
          });
        })
        .catch(error => {
            notifyUser("Error on fetching personal infos from database : " + getErrorMessage(error), true, enqueueSnackbar, closeSnackbar);
        })
      requestSent.current = true;
    }
  }, [user])

  useEffect(() => {
    setVisibleInfos(infos);
  }, [infos]);

  const requestHandler = () => {
    if (user) {
      axios.post(ID_PROVIDER_API_URL + ACCOUNTS_UPDATE_PERSONAL_INFOS_ROUTE, {
        "jwt": user.jwt,
        "infos": visibleInfos,
      })
        .then(() => {
          notifyUser("Your personal infos were saved.", false, enqueueSnackbar, closeSnackbar);
          setInfos(visibleInfos);
          setEdit(false);
        })
        .catch(error => notifyUser("An error occurred while saving your settings : " + getErrorMessage(error), true, enqueueSnackbar, closeSnackbar));
    }
  }

  const handleFirstNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setVisibleInfos({
      ...visibleInfos,
      firstName: event.target.value,
    });
  };

  const handleLastNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setVisibleInfos({
      ...visibleInfos,
      lastName: event.target.value,
    });
  };

  const handleEdit = () => {
    setEdit(true);
  };

  const cancelEdit = () => {
    setEdit(false);
    setVisibleInfos(infos);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4} sx={{textAlign: 'left'}}>
        <Typography>First Name</Typography>
        <Paper variant={"outlined"}  sx={{marginTop: 0.5, marginBottom: 1}}>
          <TextField value={visibleInfos?.firstName} error={visibleInfos?.firstName === ""} helperText={visibleInfos?.firstName === "" ? "Please provide your first name." : ""} onChange={handleFirstNameChange} fullWidth disabled={!edit} size="small"/>
        </Paper>
      </Grid>
      <Grid item xs={12} md={4} sx={{textAlign: 'left'}}>
        <Typography>Last Name</Typography>
        <Paper variant={"outlined"}  sx={{marginTop: 0.5, marginBottom: 1}}>
          <TextField value={visibleInfos?.lastName} error={visibleInfos?.lastName === ""} helperText={visibleInfos?.lastName === "" ? "Please provide your last name." : ""} onChange={handleLastNameChange} fullWidth disabled={!edit} size="small"/>
        </Paper>
      </Grid>
      <Grid item xs={12} md={4} sx={{textAlign: 'left'}}>
        <Typography>Country</Typography>
        <Paper variant={"outlined"}  sx={{marginTop: 0.5, marginBottom: 1}}>
          <CountrySelect visibleInfos={visibleInfos} setVisibleInfos={setVisibleInfos} disabled={!edit}/>
        </Paper>
      </Grid>
      <Grid item xs={12} sx={{textAlign: 'center'}}>
        {edit ?
          <>
            <Button disabled={visibleInfos?.firstName === "" || visibleInfos?.lastName === "" || visibleInfos?.country === ""} onClick={requestHandler} sx={{fontSize: "1em"}} variant="contained">
              <Typography sx={{textAlign: "center"}}>Validate</Typography>
            </Button>
            <Button onClick={cancelEdit} sx={{marginLeft: "4px", fontSize: "1em"}} variant="contained">
               <Typography sx={{textAlign: "center"}}>Cancel</Typography>
            </Button>
          </> :
          <Button onClick={handleEdit} sx={{fontSize: "1em"}} variant="contained">
            <Typography sx={{textAlign: "center"}}>Edit</Typography>
          </Button>
        }
      </Grid>
    </Grid>
  )
};