import React, {FC, useCallback, useContext, useState} from "react";
import {Box, Divider, Paper, Typography} from "@mui/material";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import {AudioFileInput} from "./components/AudioFileInput";
import {UserContext} from "../../../../../../../../context/user-context";
import {SendContext} from "../../../../../../../../context/send-context";
import {AudioTips} from "./components/AudioTips";
import {AudioPlayer} from "./components/audio_player/AudioPlayer";
import {AudioRecorder} from "./components/AudioRecorder";

interface AudioControlsProps {
    setLength: (length: number | undefined) => void;
    requestData: FormData;
}


export const AudioControls: FC<AudioControlsProps> = ({setLength, requestData}) => {
    const [file, setFile] = useState<File | undefined>(undefined);
    const {user} = useContext(UserContext);
    const {setAudioProvided} = useContext(SendContext);

    const audioCallback = useCallback((length: number | undefined) => {
        setLength(length);
        setAudioProvided(length !== undefined && (length <= 60 || user?.perm === "ADMIN"))
    }, [setAudioProvided, setLength, user]);

    return (
        <Paper sx={{marginBottom: 1, p: 1, paddingLeft: 2, width: "100%", display: 'inline-flex', alignItems: "center", borderRadius: 2}}>
            <AudioTips/>
            <AudiotrackIcon/><Typography variant="body1" sx={{marginLeft: 1}}>Audio</Typography>
            <Box sx={{flexGrow: 1}}/>
            <AudioPlayer file={file}/>
            <AudioRecorder setFile={setFile}/>
            <Divider orientation={"vertical"} flexItem />
            <AudioFileInput requestData={requestData} lengthChangeCallback={audioCallback} file={file} setFile={setFile}/>
        </Paper>
    )
}
