import {createContext} from "react";

export type User = {
    email: string,
    perm: string,
    plan: string,
    jwt: string,
    tokens: number,
    groups: string[],
}

export const UserContext = createContext<{
    user: User | undefined;
    setUser: (user: User | undefined) => void;
    validUser: (user: User | undefined) => void;
}>({
    user: undefined,
    setUser: (_: User | undefined) => {},
    validUser: (_: User | undefined) => {}
});