import axios, {AxiosResponse} from "axios";
import {getErrorMessage} from "./notificationCenter";
import {BaseData} from "./components/SelectWithLoader";
import {User} from "../context/user-context";


const CENTRAL_API_URL = process.env.REACT_APP_CENTRAL_API_URL!
const CONFIG_LANGUAGES_ROUTE = process.env.REACT_APP_CONFIG_LANGUAGES_ROUTE!
const CONFIG_ENGINES_ROUTE = process.env.REACT_APP_CONFIG_ENGINES_ROUTE!
const CONFIG_ASSETS_ROUTE = process.env.REACT_APP_CONFIG_ASSETS_ROUTE!
const PLAYGROUND_ALL_ROUTE = process.env.REACT_APP_PLAYGROUND_ALL_ROUTE!

export type LanguageType = BaseData;
export type EngineType = BaseData;
export type AssetType = BaseData & {
  "groups": string[],
  "engines": string[],
};
export type PlaygroundEntry = BaseData & {
  "language": string,
}

function fetchData(route: string): Promise<AxiosResponse<any>> {
  return axios.get(CENTRAL_API_URL + route);
}

function fetchSecuredData(route: string, user: User): Promise<AxiosResponse<any>> {
  return axios.post(CENTRAL_API_URL + route, {
    "jwt": user.jwt
  });
}

function loadData<T, >(securedFetch: boolean, user: User, sessionDataId: string, route: string, setLoading: (newState: boolean) => void, setData: (newElem: T[]) => void, setCurrent: (newElem: T | undefined) => void, replaceCurrentOnLoad: boolean) {
  const sessionData = sessionStorage.getItem(sessionDataId);
  if (sessionData == null) {
    let dataPromise;
    if (securedFetch) {
      dataPromise = fetchSecuredData(route, user);
    } else {
      dataPromise = fetchData(route);
    }
    dataPromise
      .then(res => {
        setData(res.data);
        if (replaceCurrentOnLoad) {
          setCurrent(res.data[0]);
        }
        setLoading(false);
        sessionStorage.setItem(sessionDataId, JSON.stringify(res.data));
      })
      .catch(error => {
        console.log("Error on fetching from database : ", getErrorMessage(error));
        setLoading(false);
      })
  } else {
    const parsedData = JSON.parse(sessionData);
    setData(parsedData);
    if (replaceCurrentOnLoad) {
      setCurrent(parsedData[0]);
    }
    setLoading(false);
  }
}

export function loadLanguageData(user: User, setLoading: (newState: boolean) => void, setLanguage: (newElem: LanguageType[]) => void, setCurrent: (newElem: LanguageType | undefined) => void, replaceCurrentOnLoad: boolean) {
  loadData(false, user, "languageData", CONFIG_LANGUAGES_ROUTE, setLoading, setLanguage, setCurrent, replaceCurrentOnLoad);
}

export function loadEngineData(user: User, setLoading: (newState: boolean) => void, setEngines: (newElem: EngineType[]) => void, setCurrent: (newElem: EngineType | undefined) => void, replaceCurrentOnLoad: boolean) {
  loadData(false, user, "engineData", CONFIG_ENGINES_ROUTE, setLoading, setEngines, setCurrent, replaceCurrentOnLoad);
}

export function loadAssetData(user: User, setLoading: (newState: boolean) => void, setAssets: (newElem: AssetType[]) => void, setCurrent: (newElem: AssetType | undefined) => void, replaceCurrentOnLoad: boolean) {
  loadData(true, user, "assetData", CONFIG_ASSETS_ROUTE, setLoading, setAssets, setCurrent, replaceCurrentOnLoad);
}

export function loadPlaygroundData(user: User, setLoading: (newState: boolean) => void, setEntries: (newElem: PlaygroundEntry[]) => void, setCurrent: (newElem: PlaygroundEntry | undefined) => void, replaceCurrentOnLoad: boolean) {
  loadData(true, user, "playgroundData", PLAYGROUND_ALL_ROUTE, setLoading, setEntries, setCurrent, replaceCurrentOnLoad);
}