import {getErrorMessage} from "../utils/notificationCenter";
import axios from 'axios';
import {User} from "../context/user-context";

const ID_PROVIDER_API_URL = process.env.REACT_APP_ID_PROVIDER_API_URL!
const ACCOUNTS_INFOS_ROUTE = process.env.REACT_APP_ACCOUNTS_INFOS_ROUTE!

export type PersonalInfos = {
    "firstName": string,
    "lastName": string,
    "country": string,
}

export async function provideUserInfos(token: string): Promise<User | undefined> {
    return await axios.post(ID_PROVIDER_API_URL + ACCOUNTS_INFOS_ROUTE, {
        "jwt": token
    })
        .then(res => {
            return {
                email: res.data.email,
                plan: res.data.plan,
                perm: res.data.perm,
                jwt: token,
                tokens: res.data.tokens,
                groups: res.data.groups
            }
        })
        .catch(error => {
            console.log("Could not retrieve user information : ", getErrorMessage(error))
            return undefined
        });
}