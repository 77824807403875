import React, {FC, useContext, useEffect, useState} from "react";
import {Button} from "@mui/material";
import {UserContext} from "../../../../../../../../../context/user-context";
import {SendContext} from "../../../../../../../../../context/send-context";
import {getErrorMessage, notifyUser} from "../../../../../../../../../utils/notificationCenter"
import {useSnackbar} from "notistack";
import {WordType} from "../../../DataInputPanel";
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import axios from 'axios';


interface Props {
    setValidationList: (newList: {[word: string]: WordType}) => void,
    setValidText: (newText: string[]) => void,
    requestData: FormData,
}

const CENTRAL_API_URL = process.env.REACT_APP_CENTRAL_API_URL!;
const VERIFY_TEXT_ROUTE = process.env.REACT_APP_VERIFY_TEXT_ROUTE!;

export const VerifyButton: FC<Props> = ({setValidationList, setValidText, requestData}) => {
    const {user, validUser} = useContext(UserContext);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const {spammingProtection, setTextVerified, textProvided, setSpammingProtection} = useContext(SendContext);
    const [isErrored, setErrored] = useState<boolean>(true);

    useEffect(() => {
        setErrored(spammingProtection || !textProvided || user === undefined)
    }, [spammingProtection, textProvided, user])

    const buttonHandler = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        //firstly check if the user is connected
        validUser(user)
        if (!user) {
            notifyUser("You need to be connected to send request", true, enqueueSnackbar, closeSnackbar)
            return
        }
        setSpammingProtection(true)
        notifyUser("Sending verification request to server...", false, enqueueSnackbar, closeSnackbar)
        axios.post(CENTRAL_API_URL + VERIFY_TEXT_ROUTE, {
            "jwt": user.jwt,
            "language": requestData.get("language"),
            "text": requestData.get("text")
        })
            .then(res => {
                setSpammingProtection(false)
                setValidText(res.data.new_text.split(' '));
                setValidationList(res.data.word_types);
                setTextVerified(true);
                validUser(user)
            })
            .catch(error => {
                notifyUser("An error occurred while verifying text : " + getErrorMessage(error), true, enqueueSnackbar, closeSnackbar)
                setSpammingProtection(false);
            });
    }
    return (
        <Button sx={{padding: 0, paddingLeft: 1, paddingRight: 1, marginRight: 1, marginLeft: 1}}  disabled={isErrored} variant="contained" onClick={buttonHandler} startIcon={<ManageSearchIcon/>}>
            Verify
        </Button>
    )
}
