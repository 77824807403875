import {FC, useState} from "react";
import {
    Divider,
    Grid,
    ListItem,
    ListItemButton,
    ListItemText,
    Paper,
    Typography
} from "@mui/material";
import {KeyboardArrowDown} from "@mui/icons-material";
import {HistoryFileDownloadButton} from "./HistoryFileDownloadButton";
import {HistoryFileDeleteButton} from "./HistoryFileDeleteButton";

export interface RequestProps {
    data: AnimationRequest,
    setRequests: (callback: ((prevRequests: AnimationRequest[]) => AnimationRequest[])) => void
}

export type AnimationRequest = {
    "outputFile": string,
    "inputs": string[],
    "cost": number,
    "date": string,
    "dataPath": string,
    "weight": string,
    "_id": string
}

export const Request: FC<RequestProps> = ({data, setRequests}) => {
    const [open, setOpen] = useState<boolean>(false);

    return (
        <ListItem sx={{backgroundColor: "#340b64CC", borderRadius: "8px", marginBottom: "16px"}}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ListItemButton alignItems="flex-start" onClick={() => setOpen(!open)}>
                        <KeyboardArrowDown
                            sx={{
                                transform: open ? 'rotate(-180deg)' : 'rotate(0)',
                                transition: '0.2s',
                            }}
                        />
                        <ListItemText
                            primary={"Animation : " + data.outputFile}
                            secondary="Date, Cost, Size, Input text, Input audio"
                            secondaryTypographyProps={{
                                noWrap: true,
                                lineHeight: '16px',
                                color: open ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0.5)',
                            }}
                            sx={{ my: 0 }}
                        />
                        <HistoryFileDownloadButton dataPath={data.dataPath} filename={data.outputFile}/>
                        <HistoryFileDeleteButton self={data} setRequests={setRequests}/>
                    </ListItemButton>
                    <Divider/>
                </Grid>
                {open &&
                    <>
                    <Grid item xs={12} md={12} xl={4}>
                        <Paper variant="outlined" sx={{height: "60px", padding: 1, paddingLeft: 2, display: "flex", justifyContent: "center"}}>
                            <Typography sx={{flexGrow: 1}} alignSelf="center">Date:<br/>{new Date(data.date).toUTCString()}</Typography>
                            <Typography sx={{flexGrow: 1}} alignSelf="center">Cost:<br/>{data.cost} tokens</Typography>
                            <Typography sx={{flexGrow: 1}} alignSelf="center">Size:<br/>{(parseInt(data.weight)/1e6).toFixed(2)} MB</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} xl={4}>
                        <Paper variant="outlined" sx={{height: "60px", padding: 1, display: "flex", justifyContent: "center"}}>
                            <HistoryFileDownloadButton dataPath={data.dataPath} filename={data.inputs[1]}/>
                            <Typography sx={{display: "inline", flexGrow: 1}} alignSelf="center">Input text: {data.inputs[1]}</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} xl={4}>
                        <Paper variant="outlined" sx={{height: "60px", padding: 1, display: "flex", justifyContent: "center"}}>
                            <HistoryFileDownloadButton dataPath={data.dataPath} filename={data.inputs[0]}/>
                            <Typography sx={{display: "inline", flexGrow: 1}} alignSelf="center">Input audio: {data.inputs[0]}</Typography>
                        </Paper>
                    </Grid>
                    </>
                }
            </Grid>
        </ListItem>
    )
}