import IconButton from "@mui/material/IconButton";
import DownloadIcon from '@mui/icons-material/Download';
import React, {FC} from "react";

interface AudioDownloadButtonProps {
  disabled: boolean;
  file: File | undefined;
}

export const AudioDownloadButton: FC<AudioDownloadButtonProps> = ({disabled, file}) => {
  const handleDownload = () => {
    if (file) {
      const url = window.URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = url;
      a.download = file.name;
      a.click();
      return null;
    }
  };

  return (
    <IconButton disabled={disabled} color="primary" onClick={handleDownload} sx={{p: 0, paddingLeft: 1}}>
      <DownloadIcon/>
    </IconButton>
  );
}